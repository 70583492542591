import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../../../../../UserContext'
import axios from 'axios'

function ThankYouDeposit({changeStepProcess, closeProductModal}) {

    const closeTheModal = async() => {
        closeProductModal()
        changeStepProcess(1)
    }

  return (
    <div>
        <h2 className="text-center">Thank You</h2><br />

        <p>Thank you for submitting your request</p><br />

        <div>
            <p>Our accounts department will review this request and get back to you as soon as possible. You will receive an email once the coins have been
            allocated to your wallet.</p>
        </div>

        <br />

        {/* Navigation Buttons */}
        <div className='mt-3'>



        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #226A5E",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {closeTheModal()}}>Close</Button>
        </div>
            
    </div>
  )
}

ThankYouDeposit.propTypes = {
    closeProductModal: PropTypes.func,
    changeStepProcess:PropTypes.func,
}

export default ThankYouDeposit