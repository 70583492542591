import React, { useState } from 'react'

import ProductsReport from '../../components/Containers/Reports/ProductsReport'
import TransactionsReport from '../../components/Containers/Reports/TransactionsReport'
import axios from 'axios'

import decNews from './December_Newsletter.pdf'
import SepNews from './September_Newsletter.pdf'
import OctNews from './October_Newsletter.pdf'
import NovNews from './November_Newsletter.pdf'
import FebNews24 from './February_24_Newsletter.pdf'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dropzone from "react-dropzone"

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroup,
  ModalBody

} from "reactstrap";

import { Link } from "react-router-dom"

import BenificiaryForm from '../../assets/attachments/C1WM_Beneficiary_Designation_Form.pdf'
import { UserContext } from '../../UserContext'
import LoaderDisplay from '../../components/Loading/LoaderDisplay'

const NewsletterBlock = ({ month, day, link }) => (
  <a href={link} target="_blank" rel="noreferrer">
    <div className="w-16 h-16 sm:w-20 sm:h-20 md:w-16 md:h-16 lg:w-20 lg:h-20 flex items-center justify-center mx-2 mb-4 bg-transparent border-2 border-solid rounded-full" style={{borderColor: "#226A5E"}}>
      <div className="text-center">
        <h4 className="text-xl font-bold mb-1">{month}</h4>
        <p className="text-sm">{day}</p>
      </div>
    </div>
  </a>
);


function Reports() {
  const{auth,user} = React.useContext(UserContext)
  const [userid, setUserid] = useState(null)

  React.useEffect(()=>{
    
    if(auth && user)
    {
      setUserid(user.user.userid)
      userBeneficiaryUpload(user.user.userid)
    }

  },[auth])

  const newsletters = [];

  const [selectedMenuItem, setSelectedMenuItem] = useState(1)
  const [showUploadField, setShowUploadField] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [userBeneficiaryForm, setuserBeneficiaryForm] = useState([])
  const [alreadyUploadedForm, setAlreadtUploadedForm] = useState(false)
  const [uploadedBeneficiaryForm, setUploadedBeneficiaryForm] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleItemClick = async(item) => {
    setSelectedMenuItem(item)
}

const handleDownloadForm = () => {
  // Define the path to the Beneficiary Form PDF file
  const formFilePath = BenificiaryForm;

  // Create a temporary anchor element to trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = formFilePath;
  downloadLink.download = 'Beneficiary_Designation_Form.pdf';

  // Trigger the download
  downloadLink.click();
};

function handleAcceptedFiles(files) {
  const acceptedPdfTypes = ['application/pdf'];

  const filteredFiles = files.filter(file => acceptedPdfTypes.includes(file.type));

  if (filteredFiles.length === 0) {
    displayErrorMessage('Please upload a PDF file.');
    return;
  }

  filteredFiles.forEach(file => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    });
  });

  setselectedFiles(filteredFiles);
  setuserBeneficiaryForm(filteredFiles);
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const uploadSignedForm = async() => {

    if(typeof userBeneficiaryForm[0] === 'undefined')
        {
          return displayErrorMessage("Please upload your signed benficiary form.")
        }


          var formD = new FormData();
          formD.append('userid', userid)
          formD.append("beneficiaryForm",userBeneficiaryForm[0])

          // Now lets update the user beneficiary form
          await axios.post(process.env.REACT_APP_BASE + "api/upload-signed-beneficiary-form", formD).then(async res =>{
           
            if(res.data.status === 200)
            {
              displaySuccessMessage("Form Uploaded Successfully!");
              setTimeout(() => {window.location.reload(); }, 2000);
            }else{
              displayErrorMessage("Unable To Upload Beneficiary Form!");
            }
          })
}

const displaySuccessMessage = async(message) => {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
}

const displayErrorMessage = async(message) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
}


const userBeneficiaryUpload = async(userID) => {
  
  var userObj = {
    userid: userID,
    meta_key: "benficiary_form"
  }
  await axios.post(process.env.REACT_APP_BASE + "api/get-meta", userObj).then(async res =>{
    if(res.data.status === 200)
    {
   
      setUploadedBeneficiaryForm(res.data.body.file)
      setAlreadtUploadedForm(true);
    }else{
    
      setAlreadtUploadedForm(false);
    }
  })
}

const downloadSignedForm = async() => {

  setLoading(true)

  var downloadLink = process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+ uploadedBeneficiaryForm;
  // Create an anchor element
  const anchor = document.createElement('a');
  anchor.href = downloadLink;

  // Set the download attribute to specify the filename
  anchor.download = uploadedBeneficiaryForm;

  // Trigger a click event to initiate the download
  anchor.click();

  // Remove the anchor element from the DOM
  anchor.remove();

  // setLoading(false)
}


return (
  <div className="container mx-auto px-2 pt-8 pb-8">

    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />

    <div className='mb-5'>

      <h3 className="mb-6 mt-6 text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">Documents</h3>

      <div className="menu-container">
        <div className={`menu-item cursor-pointer ${selectedMenuItem == 1 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(1)}} >Reports</div>
        <div className={`menu-item cursor-pointer ${selectedMenuItem == 2 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(2)}}>Newsletters</div>
        {/* <div className={`menu-item ${selectedMenuItem == 3 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(3)}}>My Documents</div> */}
      </div>

      {/* Reports */}
      {selectedMenuItem == 1 ?
        <div className="container-md mx-auto px-4">

          <h3 className="mb-6 mt-6 text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">Reports</h3>
          <div className="container-md px-4 primary-br-2">
              <ProductsReport filter={true} showAll={true} />
              <TransactionsReport filter={true} showAll={true} /> 
          </div>
        </div>
      : ''}

      {/* Newsletters */}
      {selectedMenuItem == 2 ?
        <div className='mb-5 mt-5'>
          <span><h3 className="text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">Newsletters</h3><small> (Click on preferred newsletter date to download)</small></span>
          <div className="flex flex-wrap -mx-2 mt-3">
            {newsletters && newsletters.length > 0 ?
            newsletters.map((newsletter, index) => (
              <NewsletterBlock key={index} {...newsletter} />
            ))
            : <p>No newsletters available at the moment!</p>}
          </div>
        </div>
      : ''}

      {/* My Documents */}
      {selectedMenuItem == 3 ?
        <div className='mb-5 mt-5'>
       
          <span><h3 className="text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">My Documents</h3></span>
          <br /><hr /><br />
          <div className='mt-3'>
            <h5 className='text-white text-lg'>Beneficiary Form</h5>
          {alreadyUploadedForm ?

            <div className=" mt-3">
              {loading ?
                <p>Loading...</p>
              :
              <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={downloadSignedForm}>
                Download Signed Form
              </button>
              }
            </div>
          :
            <div>
             
              <div className=" mt-3">
                <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={handleDownloadForm}>
                  Download Blank Beneficiary Designation Form
                </button>
              </div>
              <h2 className='text-white mt-2 mb-2 text-left ml-2'>OR</h2>
              <div className="mt-3">
                <button onClick={() => setShowUploadField(!showUploadField)}  className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                  Upload Signed Beneficiary Designation Form
                </button>
              </div>

              {showUploadField ?
                <div>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {userBeneficiaryForm.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  style={{maxHeight: "150px"}}
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} type="file" name="userUpload" />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop Signed Beneficiary Form Or Click to Upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <br />
                  <div className=" mt-3">
                  <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={uploadSignedForm}>
                  Submit Signed Form
                </button>
              </div>
                </div>
              : null}
            </div>
          }
           </div>
           <br /><hr /><br />
        </div>
      : ''}
    </div>
  </div>
);
};

export default Reports