import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faQuestionCircle, faPlus, faHistory, faUser } from "@fortawesome/free-solid-svg-icons";
import PopoverTour from '../../Modals/Tours/PopoverTour';
import axios from 'axios'

function TransactionHandleModal({modalOTP, showZar, countryCurrency, userid=null}) {

    const [fromValue, setFromValue] = React.useState('');
    const [displayWuthdrawalFee, setDisplayWuthdrawalFee] = React.useState(false);
    const [tourObject, setTourObject] = React.useState({});
    const [showPopover, setShowPopover] = useState(false);
    const [tokenCurrency, setTokenCurrency] = useState(false);

    const currencyChange = (from) => {
        if(from === 'usdt2')
        {
            setDisplayWuthdrawalFee(true);
        }else
            setDisplayWuthdrawalFee(false);
        
        modalOTP["from"] = from;
        setFromValue(from)
        // Close the popover when a currency is selected
        setShowPopover(false);
    };

   
    const getHelperFunction = async() => {
        // deposit
        // exchange
        // withdrawal
     
        if (modalOTP["type"] === "deposit") {
            var tourObj = { 
                element: '.from-currency-tour', 
                popover: { 
                    title: 'Depositing Currency', 
                    description: 'Please select the currency you are depositing from.',
                    side: "left",
                    align: 'start',
                    allowClose: true,
                } 
            };
          
            setTourObject(tourObj);
            setShowPopover(true);
        }

        if (modalOTP["type"] === "withdraw") {
            var tourObj = { 
                element: '.from-currency-tour', 
                popover: { 
                    title: 'Withdrawal Currency', 
                    description: 'Please select the currency you would like to receive your withdrawal in. Note that you may need to navigate to the exchange page to convert your funds to your desired currency.',
                    side: "top",
                    align: 'start',
                    allowClose: true,
                } 
            };
       
            setTourObject(tourObj);
            setShowPopover(true);
        }

        if (modalOTP["type"] === "exchange") {
            var tourObj = { 
                element: '.from-currency-tour', 
                popover: { 
                    title: 'Exchange From Currency', 
                    description: 'Kindly choose the currency you wish to exchange into another currency.',
                    side: "left",
                    align: 'start',
                    allowClose: true,
                } 
            };
          
            setTourObject(tourObj);
            setShowPopover(true);
        }
    };


    const getUserTokenCurrency = async() => {
        var userObj = {
            userid: userid,
            "currencyTitle": "c1t"
          }
          await axios.post(process.env.REACT_APP_BASE + "api/user-currency-acceptable", userObj).then(async res =>{
            if(res.data.status === 200)
            {
    
                setTokenCurrency(true)
            }
          });
    
    }

    if(userid)
        getUserTokenCurrency()


  return (
    <div>
        <div className="mb-4">
        {showPopover && <PopoverTour popupInfo={tourObject} />} {/* Render the PopoverTour conditionally */}
            {/* {!showZar?
            <small className="text-red-500 ">No bank details submitted, please update bank details on the Settings page to withdraw {countryCurrency}</small>
            : ''} */}

            {displayWuthdrawalFee? 
                <small className="text-red-500 ">Kindly be advised that there is a withdrawal fee of 27 USDT associated with transactions involving USDT ERC-20.</small>
            : !showZar?
            <small className="text-red-500 ">No bank details submitted, please update bank details on the Settings page to withdraw {countryCurrency}</small>
            : ''}

            {modalOTP["type"] === "exchange" ? 
            <small className="text-red-500 ">BTC withdrawals unavailable till further notice.</small>
            : ''}

            {/* Notice for Dec and Jan */}
            <div>
                <button onClick={() => getHelperFunction()} className="text-white text-sm underline hover:text-primary-color">
                    Need Help <FontAwesomeIcon icon={faQuestionCircle} className='ml-1' />
                </button>
            </div>
            
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                Currency {modalOTP["type"] === "exchange" ? "From" : ''}
            </label>
            <select value={fromValue} onChange={(e) => {
                currencyChange(e.target.value)
                setShowPopover(false);
                }} className="from-currency-tour form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                <option value="">Select Currency</option>
                <option value="usdt">USDT {modalOTP["type"] == "deposit" ? "(TRC-20 / ERC-20)" : "TRC-20"}</option>
                {modalOTP["type"] === "withdraw"?
                  <option value="usdt2">USDT ERC-20</option>
                  : ''
                }
                {
                modalOTP["type"] === "exchange" && tokenCurrency?
                  <option value="c1t">C1 Token</option>
                  : ''
                }
                {showZar?
                <option value="zar">{countryCurrency}</option>
                : ''}
                 
                {modalOTP["type"] === "withdraw"?
                 ''
                : <option value="btc">BTC</option>}
            </select>
        </div>

    </div>
  )
}

TransactionHandleModal.propTypes = {
    modalOTP:PropTypes.any,
    countryCurrency:PropTypes.any,
}

export default TransactionHandleModal