// components/LineChart.js
import React from "react";

import Privacy from "../../assets/attachments/Privacy.pdf"
import Terms from "../../assets/attachments/Terms.pdf"
import Risk from "../../assets/attachments/Risk.pdf"

function Footer({newClass="", newStyling=""}) {

  return (
    <div className={newClass}>
        <footer className="p-4  shadow md:flex md:items-center md:justify-between md:p-6 ">
            <span className="text-sm text-white-500 sm:text-center dark:text-gray-400">© 2024 <a href="#" className="hover:underline">C1 FS</a>. All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm text-white-500 dark:text-gray-400 sm:mt-0">

                <li>
                    <a href={Risk} target="BLANK" className="mr-4 hover:underline md:mr-6 ">Risk Disclosure</a>
                </li>
                <li>
                    <a href={Privacy} target="BLANK" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
                </li>
                <li>
                    <a href={Terms} target="BLANK" className="mr-4 hover:underline md:mr-6">Terms & Conditions</a>
                </li>
            </ul>
        </footer>
    </div>
  );
}
export default Footer;