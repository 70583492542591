import PropTypes from "prop-types";

import React, { useState, useEffect, useRef } from "react";

import "../../index.css";

import { withRouter, Link, Redirect } from "react-router-dom";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import authenticationLogo from "../../assets/uploads/logo/c1logo.png";

// Icons
import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";


import { UserContext } from "../../UserContext";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LoadingText from "../../components/Loading/LoadingText";

import FooterLogin from "../../components/HorizontalLayout/FooterLogin.js";

import Privacy from "../../assets/attachments/Privacy.pdf"
import Terms from "../../assets/attachments/Terms.pdf"
import Risk from "../../assets/attachments/Risk.pdf"

const Login = (props) => {
  const queryParams = new URLSearchParams(window.location.search);

  const [isUserRedirected, setIsUserRedirected] = React.useState(false);
  const [loadingDisplay, setLoadingDisplay] = React.useState(false);

  const { auth, user } = React.useContext(UserContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    border: "2px solid #226A5E",
  };

  React.useEffect(() => {
    if (auth != null) {
      if (auth) {
        window.location.href = "/dashboard";
      } else {
        const getUserToken = queryParams.get("complete");

        if (getUserToken !== "" && getUserToken !== null) {
          setIsUserRedirected(true);
        }
      }
    }

    const geErrorToken = queryParams.get("error");

    if (geErrorToken !== "" && geErrorToken !== null) {
      toast.error("Unable To Login, Please try again later", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [auth]);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [show2faMofal, setShow2faMofal] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const [gauthCode, setGAuthCode] = React.useState("");

  const [ip, setIP] = useState("");
  const [location, setLocation] = useState("");

  const onLoginClick = async () => {
    setLoadingDisplay(true);
    // if(ip === '')
    //   var details = await checkIPDetails();

    var formD = new FormData();
    formD.append("username", email);
    formD.append("password", password);
    formD.append("isAdmin", true);
    formD.append("details", location);
    // setLoader(true)

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/login`, formD, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status == 200) {
          // It is successfull client is logged in
          // if(res !== undefined){
          //   // toastr.success(res.data.message)
          // }

          if (res.data.auth) {
            if (res.data.gauth) {
              setShow2faMofal(true);
              setGAuthCode(res.data.gauth);
              setLoadingDisplay(false);
            } else {
              if (isUserRedirected)
                window.location.href = "/settings#kycfields?complete=true";
              else window.location.href = "/dashboard";

              // setLoadingDisplay(false)
            }
          } else {
            setLoadingDisplay(false);
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoadingDisplay(false);
        }
        // else{
        //   // toastr.error(res.data.message)
        // }
      });

    // props.history.push('/dashboard')
  };

  const changePassword = (password) => {
    setPassword(password);
  };

  const changeEmail = (newEmail) => {
    console.log("Email changed from: " + email + " to " + newEmail)
    setEmail(newEmail);
  };

  const closeModal = async (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setShow2faMofal(false);
  };

  const confirmCode = async () => {
    // var authObj = {
    //   userid: gauthCode,
    //   token: otp
    // }
    // if(ip === '')
    //   var details = await checkIPDetails();

    var formD = new FormData();
    formD.append("username", email);
    formD.append("password", password);
    formD.append("isAdmin", true);
    formD.append("userid", gauthCode);
    formD.append("token", otp);
    formD.append("details", location);

    await axios

    .post(`${process.env.REACT_APP_API_BASE_URL}/confirm-2falogin`, formD, {
      withCredentials: true,
    })

    .then((res) => {
      if (res.data.status == 200) {
        if (res.data.auth) {
          if (isUserRedirected)
            window.location.href = "/settings#kycfields?complete=true";
          else window.location.href = "/dashboard";
        }
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  };

  function handleWheel(e) {
    if (e.deltaY !== 0) {
      e.preventDefault();
    }
  }

  const handleFocus = (event) => {
    event.target.value = ""; // clears the input field
  };

  const [otp, setOtp] = useState("");
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (event, index) => {
    var value = event.target.value;
    if (isNaN(value)) {
      return;
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];

      if (newOtp[index]) {
        // Check if the input value is more than 1 character
        if (value.length > 1) {
          // Use only the last character of the input value
          value = value.substr(-1);
        }

        // newOtp[index] = value;
        newOtp.splice(index, 1, value);
        return newOtp.join("");
      }

      newOtp[index] = value;

      return newOtp.join("");
    });
    if (index < 5 && value) {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default form submission behavior
      onLoginClick();
    }
  };

  const togglePasswordVisibility = async() => {
    console.log("Show the hidden password, it is currently: ", showPassword)
    setShowPassword(!showPassword)
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {show2faMofal ? (
        <Modal
          open={show2faMofal}
          onClose={closeModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className="form-no-background"
        >

        <Box
          sx={{
            ...style,
            width: 400,
            background: "	rgba(5,0,5,0.9)",
          }}
        >

          <h2
            id="parent-modal-title"
            className="text-center text-lg underline mb-3"
          >

            2FA Autentication{" "}

          </h2>

          <p>
            Please open the google authenticator app and enter the code for C1
            Wealth in the input box below:
          </p>

          <div className="w-full" style={{ margin: " 15px auto" }}>

            <div style={{ margin: "auto", width: "fit-content" }}>

              {Array.from({ length: 6 }).map((_, index) => (

                <input
                  key={index}
                  type="number"
                  min="0"
                  max="9"
                  value={otp[index] || ""}
                  onChange={(event) => handleOtpChange(event, index)}
                  onFocus={handleFocus}
                  style={{ width: "30px" }}
                  onWheel={handleWheel}
                  className={`form-control text-center form-no-background shadow appearance-none border rounded py-2 text-white-700 leading-tight ${
                    index === 2 ? "mr-2" : ""
                  }`}
                  maxLength="1"
                  ref={refs[index]}
                />

              ))}

            </div>

          </div>

          <Button
            style={{
              background: "#040E18",
              borderRadius: "70px",
              border: "2px solid green",
              float: "right",
              color: "#fff",
            }}
            className="c1-primary-btn"
            onClick={confirmCode}
          >
            Confirm
          </Button>

          <Button
            style={{
              background: "#040E18",
              borderRadius: "70px",
              border: "2px solid #fa0a0a",
              float: "right",
              color: "#fff",
            }}
            className="c1-primary-btn"
            onClick={closeModal}
          >
            Close
          </Button>

        </Box>
        </Modal>

      ) : (

        <div className="min-h-screen flex justify-center items-center">
          {/* Background container to hold the blurred background image */}
          <div className="absolute inset-0 bg-cover bg-center z-0" ></div>

          {/* Login container to hold the login form */}
          <div className=" p-8 max-w-sm mx-auto shadow-lg z-10 relative authentication-container">
            <img src={authenticationLogo} alt="c1_logo" className="authentication-container-img" />
            <h2 className="text-center text-2xl font-bold mb-2 mt-4">Log In</h2>
            <h3 className="text-center text-sm text-white mb-6">Welcome back! Please enter your username and password to log in.</h3>

            {/* Login form */}
            <form className="space-y-4" action="" method="post">

              {/* Email input with icon */}
              <div className="relative flex items-center">
                {/* Icon */}
                <CiMail className="absolute left-3 authentication-highlight-color" size="18" />

                {/* Input */}
                <input type="text" name="email" onChange={(e) => changeEmail(e.target.value)} placeholder="Enter Email" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
              </div>

              <div className="relative">
                {/* Lock Icon */}
                <CiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 authentication-highlight-color" size="18" />

                {/* Input */}
                <input type={showPassword ? "text" : "password"} 
                  name="password" 
                  id="password" 
                  placeholder="Enter Password" 
                  required className="w-full py-2 pl-10 pr-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent"
                  onChange={(e) => changePassword(e.target.value)}
                  />

                {/* Eye Icon */}
                <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => togglePasswordVisibility()}>
                  <IoEyeOutline className={showPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
                </a>
              </div>

              {/* Forgot Password */}
              <a href="/forgot-password" class="text-xs text-right text-gray-500 block hover:text-white">Forgot Password?</a>

              {/* Submit Button */}
              <input type="submit" onClick={(e) => {e.preventDefault(); onLoginClick()}} 
                value="Log In" 
                class="w-full bg-gradient-to-r from-blue-500 to-blue-400 text-white rounded-full py-2 font-semibold transition duration-300 hover:bg-blue-600 cursor-pointer authentication-button" />

              {/* Sign Up Option */}
              <p className="text-xs text-center text-white">Don't have an account? <a href="/register/new-user" className="authentication-word-highlight authentication-word-font-size-13">Register here.</a></p>
            </form>

          </div>

          {/* Footer */}
          <div className="absolute bottom-0 left-0 w-full bg-transparent text-center text-gray-500 py-2">
            &copy; 2024 C1 FS. All Rights Reserved. | 
            <a href="mailto:info@c1fs.co.za" className="hover:underline" >Support</a> | 
            <a href={Privacy} target="BLANK" className="hover:underline" >Privacy Policy</a> | 
            <a href="https://c1fs.co.za/" className="hover:underline" >Our Website</a>
          </div>
        </div>


      )}

    </React.Fragment>

  );
  
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
