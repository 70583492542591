import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import authenticationLogo from "../../assets/uploads/logo/c1logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faEye } from "@fortawesome/free-solid-svg-icons";

import { IoEyeOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";

import { withRouter, Link, useLocation } from "react-router-dom"

import Privacy from "../../assets/attachments/Privacy.pdf"
import Terms from "../../assets/attachments/Terms.pdf"
import Risk from "../../assets/attachments/Risk.pdf"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

function UpdatePassword() {

  let query = useQuery()
  let userToken = query.get("userToken");

  const[password, updatePassword] =  React.useState('');
  const[cPassword, updateCPassword] =  React.useState('');
  const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)


  const submitButton = async() => {

    await axios.post(process.env.REACT_APP_API_BASE_URL + `/reset-password`,{password: password, userToken}).then((res)=>{
           
      if (res.data.status == 200) {

        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {window.location.href = "/login"; }, 2000);
         
      } else {
              
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      }

    })

  }

  const togglePasswordVisibility = async() => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = async() => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <React.Fragment>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
      />

      <div className="min-h-screen flex justify-center items-center">
        {/* Background container to hold the blurred background image */}
        <div className="absolute inset-0 bg-cover bg-center z-0" ></div>

        {/* Login container to hold the login form */}
        <div className=" p-8 max-w-sm mx-auto shadow-lg z-10 relative authentication-container">
          <img src={authenticationLogo} alt="c1_logo" className="authentication-container-img" />
          <h2 className="text-center text-2xl font-bold mb-2 mt-4">Reset Password</h2>
          <h3 className="text-center text-sm text-white mb-6">Please enter your new password and confirm it to reset your password.</h3>

          {/* Reset Password form */}
          <form className="space-y-4" action="" method="post">

            {/* New Password */}
            <div className="relative">
              {/* Lock Icon */}
              <CiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 authentication-highlight-color" size="18" />

              {/* Input */}
              <input type={showPassword ? "text" : "password"} 
                name="password" 
                id="password" 
                placeholder="New Password" 
                required className="w-full py-2 pl-10 pr-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent"
                onChange={(e) => updatePassword(e.target.value)}
              />

              {/* Eye Icon */}
              <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => togglePasswordVisibility()}>
                <IoEyeOutline className={showPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
              </a>
            </div>

            {/* Confrim Password */}
            <div className="relative">
              {/* Lock Icon */}
              <CiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 authentication-highlight-color" size="18" />

              {/* Input */}
              <input type={showConfirmPassword ? "text" : "password"} 
                name="password" 
                id="password" 
                placeholder="Confirm Password" 
                required className="w-full py-2 pl-10 pr-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent"
                onChange={(e) => updateCPassword(e.target.value)}
              />

              {/* Eye Icon */}
              <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => toggleConfirmPasswordVisibility()}>
                <IoEyeOutline className={showConfirmPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
              </a>
            </div>


            {/* Submit Button */}
            <input type="button" onClick={(e) => {submitButton(e)}} 
              value="Submit" 
              class="w-full bg-gradient-to-r from-blue-500 to-blue-400 text-white rounded-full py-2 font-semibold transition duration-300 hover:bg-blue-600 cursor-pointer authentication-button" 
            />

          </form>

        </div>

        <div>
          {/* Footer */}
          <div className="absolute bottom-0 left-0 w-full bg-transparent text-center text-gray-500 py-2">
            &copy; 2024 C1 FS. All Rights Reserved. | 
            <a href="mailto:info@c1fs.co.za" className="hover:underline" >Support</a> | 
            <a href={Privacy} target="BLANK" className="hover:underline" >Privacy Policy</a> | 
            <a href="https://c1fs.co.za/" target="BLANK" className="hover:underline" >Our Website</a>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
  
}

export default UpdatePassword