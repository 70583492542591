import React, {useState, useEffect} from "react";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function NoticeModal({modalDisplay, closeModal, modalOTP, handleAccept, title, showSpiner=showSpiner}) {

    var noticeTitle = `Important Notice: Capital Withdrawals`;

    var noticeMessage = `As per our terms and conditions, a 30-working-day notice period is required before the expiration date of a product plan for all Capital Withdrawals. Please submit all withdrawal requests via email to capitalwithdrawal@c1fs.co.za.`
    
    var noticeFooter = `In accordance with our November Newsletter, no Capital Withdrawals will be processed in December 2023 and January 2024. Payments will be made in February 2024`
    
    
    const [otp, setOTP] = React.useState('');

    const [steps, setSteps] = React.useState(1);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    const[showZar, setShowZar] =  React.useState(true);
    const [countryCurrency, setCountryCurrency] = React.useState('ZAR');
    const [countryCurrencyDisplay, setCountryCurrencyDisplay] = React.useState({'usdt':'USDT', 'USDT': 'USDT','btc':'BTC', 'BTC': 'BTC','zar':'ZAR', 'ZAR': 'ZAR'});

    // const[showSpiner, setShowSpinner] =  React.useState(false);

    React.useEffect(()=>{
    
      if(auth && user)
      {
      
          getUserDetails(user.user.userid)
          setUserid(user.user.userid)
          getBankConfirmationStatus(user.user.userid)

      }
    },[auth])

    const getBankConfirmationStatus = async(userid) => {
      var userObj = {
        userid: userid
      }
      await axios.post(process.env.REACT_APP_BASE + "api/confirm-uploaded-bank-details", userObj).then(async res =>{
        if(res.data.status !== 200)
        {

          if(modalOTP["type"] === "withdraw")
            setShowZar(false)
        }
      });
    }

    const getUserDetails = async (userid) => {
      var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            var mainString = res.data.info.country
            if(mainString && mainString.includes("Namibia"))
            {
              setCountryCurrency('NAD')
              setCountryCurrencyDisplay({'usdt':'USDT', 'USDT': 'USDT','btc':'BTC', 'BTC': 'BTC','zar':'NAD', 'NAD': 'NAD'})
            }
          }
        });
      }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #226A5E',
      };


  return (
    <div>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />

    <Modal
      open={modalDisplay}
      onClose={closeModal}
      disableBackdropClick
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      slotProps={{
        backdrop: {
          onClick: (event) => event.stopPropagation(), // Prevent the modal from closing on outside click
        },
      }}
      className="form-no-background"
    >
      <Box 
      sx={{
        ...style, width: 400,
        background:
          "	rgba(5,0,5,0.8)"
      }}>

      {showSpiner ?
        <div role="status" className="m-auto">
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
        :
        <div>
        <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>{noticeTitle}  </h2>

        
        <small>{noticeMessage}</small>
        <br />
        <br />
        <Button style={{    
          background: "#040E18",
          borderRadius: "70px",
          border: "2px solid #226A5E",
          color: "#fff"}} className='c1-primary-btn' onClick={handleAccept}>I Acknowledge</Button>
          
        </div>
        }
     
      </Box>
    </Modal>
  </div>
  )
}

NoticeModal.propTypes = {
    closeModal: PropTypes.func,
    modalDisplay:PropTypes.any,
    modalOTP:PropTypes.any,
    handleAccept: PropTypes.func,
    title: PropTypes.any,

}

export default NoticeModal