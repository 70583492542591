import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../../../UserContext";
import myProductsImage from "./my-products.png";

function ProductsContainer() {
  const { auth, user } = React.useContext(UserContext);
  const [displayNotifications, setDisplayNotifications] = React.useState(true);

  const toggleNotification = () => {
    setDisplayNotifications(!displayNotifications);
  }

  React.useEffect(() => {
    if (auth && user) {
      // Fetch data or perform actions related to authentication and user data here
    }
  }, [auth]);

  return (
    <div className="tour-my-products">
      <div>
        My <span className="primary-color">Products <FontAwesomeIcon onClick={toggleNotification} className="pl-2" icon={faCaretDown} size="xl"  /></span>
      </div>
      {displayNotifications ?
        <div className="mt-2">
          <p className="text-white">Click to view <span className="primary-color"><a href="/my-packages">My Products</a></span></p>
          
          {/* Image here */}
          <div className="relative">
            <a href="./my-packages" >
              <img src={myProductsImage} alt="My Products" className="w-full h-auto max-h-96 object-cover" />
              <div className="absolute inset-0 bg-gray-900 opacity-70 rounded-lg"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                {/* <div className="relative bg-primary-color py-2 px-4 rounded-lg hover:bg-primary-color-dark transition duration-300"
                  style={{background: "black", opacity: "0.5",color: "white"}}>
                  <span className="glass-effect"></span>
                  <span className="text-opacity-50">My Products</span>
                  </div> */
                }
              </div>
            </a>
          </div>
        </div>
        : ''}
    </div>
  )
}

export default ProductsContainer;
