import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function BlockedWithdrawalModal({modalDisplay, closeModal, description, title}) {

    
    const [otp, setOTP] = React.useState('');

    const [steps, setSteps] = React.useState(1);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    React.useEffect(()=>{
    
      if(auth && user)
      {
   
          setUserid(user.user.userid)
      }
    },[auth])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #226A5E',
      };


  return (
    <div>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />

    <Modal
      open={modalDisplay}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"

      className="form-no-background"
    >
      <Box 
      sx={{
        ...style, width: 400,
        background:
          "	rgba(5,0,5,0.9)"
      }}>
        <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>{title} </h2>

        <p>{description}</p>


            <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>

     
      </Box>
    </Modal>
  </div>
  )
}

BlockedWithdrawalModal.propTypes = {
    closeModal: PropTypes.func,
    modalDisplay:PropTypes.any,
    title: PropTypes.any,
    description: PropTypes.any
}

export default BlockedWithdrawalModal