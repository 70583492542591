// components/LineChart.js
import React from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


import axios from 'axios'
import ExterNalProducts from "../../Products/External";

function AccountCompletion({userid, kyc, account, openProductModal, userName}) {

    var accountCompletionPercentage = {
        0: 0,
        1: 50,
        2: 100
    }

    var accountCompletionLabel = {
        0: "Requires KYC",
        1: "Pending Admin Approval",
        2: "Account Completed"
    }

    var accountActiveCompletionLabel = {
        0: "Inactive",
        1: "Pending",
        2: "Active",
        3: "Locked",
    }

    

 
    return (
        <div className="tour-account-info"> 
            <div>My <span className="primary-color">Account Completion <FontAwesomeIcon  className="pl-2" icon={faCaretDown} size="xl"  /></span></div>
            
            <div>
                <p className="text-white"><span className="primary-color">Userid:</span> {userid}</p>
                <p className="text-white"><span className="primary-color">User:</span> {userName}</p>
                <p className="text-white kyc-status-tour"><span className="primary-color">Account:</span> {accountActiveCompletionLabel[account]}</p>
                <p className="text-white"><span className="primary-color">KYC (Know Your Client):</span> {accountCompletionLabel[kyc]}</p>
                {kyc !== 0?
                    <div className="w-full bg-gray-200 mb-4 rounded-full dark:bg-gray-700 mt-3">
                        <div className="secondary-bg text-xs font-medium text-black text-bold text-center p-0.5 leading-none rounded-full" style={{width: accountCompletionPercentage[kyc]  + "%"}}> { accountCompletionPercentage[kyc]}%</div>
                    </div>
                    : 
                    <a href="/settings?kycfields" className="c1-primary-btn kyc-navigate-button text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 mb-4" type="button">
                        Navigate To KYC
                    </a>
                }
            </div>

            {/* <ExterNalProducts openProductModal={openProductModal} /> */}

        </div>
    );
}
export default AccountCompletion;