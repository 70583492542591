import React, { useState, useEffect } from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fas } from "@fortawesome/free-solid-svg-icons";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { UserContext } from "../UserContext";

function ExchangeForm({ products, min, max = null }) {

    const [selectedCurrency, setSelectedCurrency] = React.useState('');
    const [exchangeToCurrency, setExchangeToCurrency] = React.useState('');
    const [exchangeAmount, setExchangeAmount] = React.useState(0);
    const [finalExchangeAmount, setFinalExchangeAmount] = React.useState(0);
    const [userid, setUserid] = React.useState('');

    const [userBalances, setUserBalances] = useState([]); 
    const [getShowSpinner, setShowSpinner] = React.useState(false);

    const [exchangeSettings, setExchangeSettings] = React.useState(null);
    const [displayLoader, setDisplayLoader] = React.useState(false);
    const [displayMainLoader, setDisplayMainLoader] = React.useState(false);
    const [exchangeRates, setExchangeRates] = React.useState({});
    const [exchangeRateAmount, setExchangeRateAmount] = React.useState(0);

    const [ExchangeRate, setExchangeRate] = React.useState(0);
    const [ExchangeFee, setExchangeFee] = React.useState(0);

    const [canContinueToOTP, setCanContinueToOTP] = React.useState(false);

    const [seconds, setSeconds] = useState(120); // 2 minutes in seconds
    const [isNADCurrency, setIsNADCurrency] = useState(false);
    
    const [steps, setSteps] = React.useState(1);

    const [OTPPin, setOTPPin] = React.useState(null);
    const [attempts, setAttempts] = React.useState(3);
    

    const { auth, user } = React.useContext(UserContext)


    React.useEffect(() => {

        if (auth && user) {
            getUserDetails(user.user.userid)
            getExchangeSettings(user.user.userid)
            getExchangeRates();
            setUserid(user.user.userid)
   
            saveUserActivity(user.user.userid, `Viewed Exchange Page`)
        }
    }, [auth])

    const getUserDetails = async (userid) => {
        var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`, userObj).then(async (res) => {
          if (res.data.status == 200) {
            var mainString = res.data.info.country
            if (mainString && mainString.includes("Namibia")) {
                setIsNADCurrency(true)
            }
          }
        });
      }


    const getExchangeSettings = async(userid) => {
            
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-exchange-settings`,{}).then(async (res)=>{
            if(res.data.status == 200)
            {

              setExchangeSettings(res.data.settings)
              await getUserBalance(userid, res.data.settings);
            }
        })
      
          return true;
    }

    const saveUserActivity = async (userid, activity) => {

        var activityData = {
            userid: userid,
            activity: activity
        }



        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`, activityData).then(async (res) => {

        })
    }

    const getUserBalance = async(userid, setting) =>{
        setDisplayMainLoader(true)
        var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balances`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
        
            setUserBalances(res.data.currencies)
            setDisplayMainLoader(false)
          }
          
        })
    
        return true;
      }


    const sendOTP = async () => {

        var otpObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp", otpObj).then((res) => {

            if (res.data.status == 200) {

                toast.success('OTP Pin Successfully Sent!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                setShowSpinner(false)

            } else {

                toast.error('Unable To send OTP!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                setShowSpinner(false)
            }

        })

    }


    const handleCurrencyChange = async (e) => {
  
        setSelectedCurrency(e.target.value);
        await onAmountChange(0)
    };
    
    const handleExchangeToChange = async (e) => {
        var newExchangeToCurrency = e.target.value;
        setExchangeToCurrency(newExchangeToCurrency);
        await onAmountChange(0)
   
    };

    const filteredCurrencies = userBalances.filter(currency => currency.id !== selectedCurrency.id);

    const getExchangeRates = async () => {
        await axios.get(`${process.env.REACT_APP_URL}exchange-rates`,{}).then(async (res)=>{
            if(res.data.status == 200)
            {
           
              setExchangeRates(res.data.rates)
            }
            
        })
    };
    
    const onAmountChange = async(amount) => {
        setExchangeAmount(amount);
        await calculateExchangeAmount(amount)
    }

    const calculateExchangeAmount = async(amount) => {
        if(!exchangeToCurrency || exchangeToCurrency === '' || !selectedCurrency || selectedCurrency === '' || amount <= 0)
            return setExchangeRateAmount(0);

        var exchangesCurrencyCombined = selectedCurrency + exchangeToCurrency;
     
        var rate = exchangeRates[exchangesCurrencyCombined];
        setExchangeRate(rate);

        var currencyDetails = await getCurrencyById(exchangeToCurrency)
        var fixedValue = 2;
        if(currencyDetails)
            fixedValue = currencyDetails.currency_decimals;

        var exchangeAmount = amount * rate;

        if(exchangeToCurrency !== '3' && selectedCurrency !== '3')
        {
            var exchangeFeePercentage = exchangeSettings.exchange_rate_fee / 100;

            var exchangeRateFee = exchangeAmount * exchangeFeePercentage
            setExchangeFee(exchangeRateFee);
    
            var AmountLessFee = exchangeAmount - exchangeRateFee;


            setExchangeRateAmount(AmountLessFee.toFixed(fixedValue));
        }else{
            setExchangeFee(0)
            setExchangeRateAmount(exchangeAmount);
        }
     

    }

    const getCurrencyById = async(id) => {
        return userBalances.find(currency => currency.id === id);
    }

    const continueToNext = async() => {
   
        if(exchangeRateAmount <= 0 || !exchangeRateAmount || isNaN(exchangeRateAmount))
            return;

        setCanContinueToOTP(false)
        var nextStep = steps + 1;
        setSteps(nextStep);

        if(nextStep == 2)
        {
            await sendOTP();
        }
    }

    const goBack = async() => {
        if(steps > 1)
        {
            setCanContinueToOTP(false)
            var nextStep = steps - 1;
            setSteps(nextStep);
        }

    }

    const confirmOTP = async() => {
        setDisplayMainLoader(true)
        
    var otpObj = {
        userid: userid,
        otp: OTPPin
      }

    const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

        if (res.data.status !== 200) {
            if(attempts <= 1)
            {
                setAttempts(3);
                toast.error(`Incorrect OTP PIN!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });

                  await sendOTP();
                  setDisplayMainLoader(false)
                  return false;
            }
            var attemptsLeft = attempts - 1
            setAttempts(attemptsLeft);
          toast.error(`Incorrect OTP PIN, ${attemptsLeft} left!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
  
          setDisplayMainLoader(false)
  
          return false;
        } else {
          return true;
        }
      });
  
      if (!checkOtp)
        return false;
  
      var balanceObject = {
        userid: userid,
        currency: selectedCurrency
      }

      var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject).then(async res => {
  
        if (parseFloat(res.data.total) < exchangeAmount) {
          toast.error('Insufficient Balance!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
  
          setDisplayMainLoader(false)
  
          return false;
        } else {
          return true
        }
  
      })
  
      if (checkBalance)
        await handleTransaction();
  
    }

    const handleTransaction = async() => {
  

        setDisplayMainLoader(true)
        
            const exchangeObject = {
              userid: userid,
              excahnge_type: '1',
              amount_from: selectedCurrency,
              amount_to: exchangeToCurrency,
              status: '0',
              amount: parseFloat(exchangeRateAmount),
              from_amount: parseFloat(exchangeAmount),
              exchange_rate: parseFloat(ExchangeRate),
              exchange_amount: parseFloat(exchangeRateAmount) + parseFloat(ExchangeFee),
              fee: parseFloat(ExchangeFee),
            }
        
        
        
            //  return false;
        
            await axios.post(process.env.REACT_APP_BASE + "api/create-exchange", exchangeObject).then(async res2 => {

        
              if (res2.data.status == 200) {
                toast.success('Exchange Created Successfully!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
        
                setTimeout(() => { window.location.reload(); }, 2000);
              } else {
                toast.error('Unable To Create Exchange', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
                setDisplayMainLoader(false)
              }
        
            })

          
          
    }


    return (

        <div className="container-md mx-auto px-4">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <div className="grid grid-cols-1 gap-6 md:grid-cols-1 lg:grid-cols-1 primary-border p-4">

                <div className="px-4 pl-6">
                    <div className="w-full">
                        {!displayMainLoader ?
                        <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-auto lg:w-10/12">
                            {/* ^^^ added mx-auto to center the form horizontally */}
                            {steps === 1 ?
                            <div className="mb-4">
                                <h1 className="title text-white text-center mb-2">Exchange Form</h1>
                          
                                <small className="text-red-500 ">BTC withdrawals unavailable till further notice.</small>
        
                                <div className="mt-2 mb-1">
                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="exchangeFrom">
                                        Exchange From
                                        <small className="text-gray-400"> (Select currency to exchange)</small>
                                    </label>
                                    <select onChange={handleCurrencyChange} value={selectedCurrency.id} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                                        <option value="">Select Currency</option>
                                        {userBalances.map(currency => (
                                            currency.exchange === "true"?
                                                <option key={currency.id} value={currency.id}>{currency.name == 'ZAR' && isNADCurrency ? 'NAD' :  currency.name}</option>
                                            : ''
                                        ))}
                                    </select>
                                </div>

                                <div className="mt-2 mb-1">
                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="exchangeTo">
                                        Exchange To
                                        <small className="text-gray-400"> (Select currency to exchange to)</small>
                                    </label>
                                    <select onChange={handleExchangeToChange} value={exchangeToCurrency.id} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                                        <option value="">Select Currency</option>
                                        {filteredCurrencies.map(currency => (
                                            currency.exchange === "true"?
                                                <option key={currency.id} value={currency.id}>{currency.name == 'ZAR' && isNADCurrency ? 'NAD' :  currency.name}</option>
                                            : ''
                                        ))}
                                    </select>
                                </div>


                                <div className="mt-2 mb-1">
                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="exchangeFrom">
                                        Amount
                                    </label>
                                    <input className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" type="number" step="0.01" min="1" 
                                        disabled = {!selectedCurrency || selectedCurrency === '' || !exchangeToCurrency || exchangeToCurrency === ''} value={exchangeAmount} onChange={(e) => {onAmountChange(e.target.value)}} />
                                </div>



                                {!displayLoader ?
                                <div className="mt-2 mb-1">
                                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="exchangeFrom">
                                        Amount <small className="text-red-600"> (3% FEE ALREADY DEDUCTED FOR ALL NON BTC EXCHANGES)</small>
                                    </label>
                                    <input className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" type="number" step="0.01" min="1" value={exchangeRateAmount} />
                                </div>
                                :
                                <div class="loader-c1fs"></div>}

                            
                            </div>
                            : steps === 2 ?
                                <div className="mb-4">
                                    <div className="mt-2 mb-1">
                                        <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="exchangeFrom">
                                            OTP
                                        </label>
                                        <input className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" type="number" step="0.01" min="1" 
                                            disabled = {!selectedCurrency || selectedCurrency === '' || !exchangeToCurrency || exchangeToCurrency === ''} value={OTPPin} onChange={(e) => {setOTPPin(e.target.value)}} />
                                    </div>
                                </div>
                            : ''}

                            <div className="flex items-center justify-between">
                                {steps === 2 ?
                                <button  onClick={(e) => {confirmOTP()}} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    Confirm OTP
                                </button>
                                :
                                 <button  onClick={(e) => {continueToNext()}} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                 Apply
                                </button>}
                                {steps > 1 ?
                                <button  onClick={(e) => {goBack()}} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    Back
                                </button>
                                : ''}
                            </div>

                         
                        </form>
                        : <div class="loader-c1fs"></div>}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ExchangeForm