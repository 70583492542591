import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'

import moment from 'moment-timezone';
import BlockedWithdrawalModal from "../../components/Modals/BlockedWithdrawalModal";
import WithdrawalContainer from "../../components/Containers/Transactions/WithdrawalContainer";
import KnowlegedModal from "../../components/Modals/KnowlegedModal";
import NoticeModal from "../../components/Containers/Transactions/Notice";

function Withdraw() {

    const [modalOTP, setModalOTP] = React.useState({otp: '', from: '', amount: '', type: 'withdraw', reference: ''});

    const[modalDisplay, setModalDisplay] =  React.useState(false);
    const[openBlockedModal, setOpenBlockedModal] =  React.useState(false);
    const[openKnowlegedModal, setOpenKnowlegedModal] =  React.useState(false);

    const[noticeModal, setNoticeModal] =  React.useState(false);
    const[displayedNoticeModal, setDisplayedNoticeMdal] =  React.useState(false);
    

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[companyId, setCompany] =  React.useState('');
    
    const[expireDay, setExpireDay] =  React.useState(7);

    const[canContinue, setCanContinue] =  React.useState(true);
    const [countryCurrency, setCountryCurrency] = React.useState('ZAR');

    const[viewHistory, setViewHistory] =  React.useState(false);
    const[hideWithdrawNowButton, setHideWithdrawNowButton] =  React.useState(true);

    const[showSpiner, setShowSpinner] =  React.useState(false);

    const [selectedERC20, setSelectedERC20] = React.useState(false);

    React.useEffect(() => {
      if (auth && user) {
        getUserDetails(user.user.userid);
        setUserid(user.user.userid);
        setCompany(user.user.company);
        setHideWithdrawNowButton(false);
        if (modalOTP['from'] === 'usdt2') {
          setSelectedERC20(true);
        }
  
        // Fetch the updated expireDay value when userId and companyId are available
        getCompanyExpireDate(user.user.company, user.user.userid)
          .then(updatedExpireDay => {
            if (updatedExpireDay !== false) {
              // Update the expireDay state with the latest value
              setExpireDay(updatedExpireDay);
            }
          })
          .catch(error => {
            // Handle the case where getCompanyExpireDate returns an error
            console.error("Error fetching expireDay:", error);
          });
  
        saveUserActivity(user.user.userid, "Viewed Withdrawal Page");
      }
    }, [auth]);

    const getUserDetails = async (userid) => {
      var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            var mainString = res.data.info.country
            if(mainString && mainString.includes("Namibia"))
            {
              setCountryCurrency('NAD')
            }
          }
        });
      }

    const saveUserActivity = async(userid, activity) => {

      var activityData = {
          userid: userid,
          activity: activity
      }

   

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`,activityData).then(async (res)=>{

      })
    }

    const getCompanyExpireDate = async (company, userid) => {
      try {
        var expireObj = {
          company: company,
          userid: userid,
        };
  
        const response = await axios.post(
          process.env.REACT_APP_BASE + "api/get-company-w-expire",
          expireObj
        );
  
        if (response.data.status === 200) {
          return response.data.date;
        } else {
          // Handle the case where the request returns a 400 error
          return 7;
        }
      } catch (error) {
        // Handle any other errors that might occur during the request
        console.error("Error fetching expireDay:", error);
        return 7;
      }
    };

    const openTransactionModal = async () => {
      try {
        // Fetch the updated expireDay value
        const updatedExpireDay = await getCompanyExpireDate(companyId, userid);
   
        if (updatedExpireDay !== false) {
          // Update the expireDay state with the latest value
          setExpireDay(updatedExpireDay);
    
          // Check if it's within the allowed period
          var currentDate = moment.tz('Africa/Johannesburg').format('YYYY-MM-DD');
          const firstDay = moment(currentDate).startOf('month');
          const seventhDay = moment(currentDate).startOf('month').add(updatedExpireDay - 1, 'day');
    
          var isBetweentheSeventh = moment(currentDate).isBetween(firstDay, seventhDay, null, '[]');
    
          if (!isBetweentheSeventh) {
            setOpenBlockedModal(true);
          } else {
            if (!displayedNoticeModal) {
              setNoticeModal(true);
              return setDisplayedNoticeMdal(true);
            }
            setModalDisplay(true);
          }
        } else {
          // Handle the case where getCompanyExpireDate returns false
          console.log("Error fetching expireDay value.");
        }
      } catch (error) {
        // Handle any errors that might occur during the fetch
        console.error("Error fetching expireDay:", error);
      }
    };

 
    const closeModal = (event, reason) => {
     
      if (reason && reason == "backdropClick") 
          return;
      setModalDisplay(false)
  }

    const closeModalWithdrawBlocked = async() => {
      setOpenBlockedModal(false)
    }

    const closeModalAnkowledge = async() => {
      setOpenKnowlegedModal(false)
    }

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
        'usdt2' : 'usdt2',
    }

    const handleAccept = async () => {
      setShowSpinner(true)


        if(modalOTP['amount'] <= 0)
        {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                setShowSpinner(false)
                return false;
        }

        if(modalOTP['reference'] === '' || !modalOTP['reference'])
        {
            toast.error('Please ensure that you have saved your wallet address or bank details in the settings field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                setShowSpinner(false)

                return false;
        }

        if(canContinue)
        {
          setCanContinue(false)

        var otpObj = {
            userid: userid,
            otp: modalOTP['otp']
          }
          
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                  
            if(res.data.status !== 200)
            {

                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    await sendOTP();
                    setShowSpinner(false)
                    setCanContinue(true)

                return false;
            }else{
          
                return true;
            }
        });

        if(!checkOtp)
            return false;

        var currencyToUse = currencyConvert[modalOTP['from']];
        if(modalOTP['from'] === 'usdt2')
        {
          currencyToUse = '1'
        }
            var balanceObject = {
                userid: userid,
                currency: currencyToUse
              }
    
         
    
          
            var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{

              var amountToCheck = 0;
             
              if(modalOTP['from'] === 'usdt2')
              {
                amountToCheck = parseFloat(modalOTP['amount']) + 27;
              }else{
                amountToCheck = parseFloat(modalOTP['amount']);
              }

   
          
             if(truncateDecimals(res.data.total, 6) < amountToCheck)
              {
                toast.error('Insufficient Withdrawal Balance!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                    setShowSpinner(false)
                    setCanContinue(true)
                    return false;
              }else{

                return true
              }
           
            })

            if(!checkBalance)
            {
              setShowSpinner(false)
              setCanContinue(true)
              return false;
            }
             

            var finalAmount = modalOTP['amount'] * -1;
        const withdrawObject = {
            userid: userid,
            withdrawal_address: modalOTP['reference'],
            amount: finalAmount,
            tran_cat: '2',
            currency: currencyConvert[modalOTP['from']],
            type: "Adding Withdrawal"
          }
     
          var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction",withdrawObject).then(async res =>{

   

            if(res.data.status == 200)
            {
                toast.success('Withdraw Created Successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    // setTimeout(() => {window.location.reload(); }, 2000);
                    setModalDisplay(false)
                    setOpenKnowlegedModal(true);
                    setShowSpinner(false)
            }else{
                toast.error('Unable To Create Withdraw', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                    setShowSpinner(false)
                    setCanContinue(true)
            }

          });

          await saveUserActivity(userid, "User Added Withdrawal")

        }else{
        setShowSpinner(false)
        }
        // setModalDisplay(false)
      }

      const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
        
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

        

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }

      function truncateDecimals(number, decimalPlaces) {
        const multiplier = 10 ** decimalPlaces;
        return Math.floor(number * multiplier) / multiplier;
      }

      const handleAknowledge = async() => {
        toast.success('Thank you.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

          setTimeout(() => {window.location.reload(); }, 2000);
      }

      const handleAknowledgeNotice = async() => {
        toast.success('Thank you.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
          setNoticeModal(false)
          setModalDisplay(true)
      }


      const closeNoticeModal = async() => {
        setNoticeModal(false)
      }
  

  return (
    <div>
      <div className="container mx-auto px-2 pt-8 pb-8">

          {modalDisplay ? 
              <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} showSpiner={showSpiner} title="Withdraw" />
              : openBlockedModal ? 
              <BlockedWithdrawalModal modalDisplay={openBlockedModal} closeModal={closeModalWithdrawBlocked} title="Unable To Withdraw" description={`Unfortunately it appears that you are trying to withdraw outside of the withdrawal period which is from the 1st to the ${expireDay}th of each month. If you have any questions, please contact info@c1fs.co.za.`} />
              : openKnowlegedModal?
              <KnowlegedModal modalDisplay={openKnowlegedModal} modalOTP={modalOTP} closeModal={closeModalAnkowledge} handleAccept={handleAknowledge} showSpiner={showSpiner} title="Notice" />
              : noticeModal ?
              <NoticeModal modalDisplay={noticeModal} modalOTP={modalOTP} closeModal={closeNoticeModal} handleAccept={handleAknowledgeNotice} showSpiner={showSpiner} title="Notice" />
              : ''
            }
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Withdraw</h1>

          <div className="container-md mx-auto px-4">

        
              <div className="container-md px-4 primary-br-2">

                  <div className="">
                  <ol type="1">
                          <li>Withdrawals can be made in the following currencies:
                              <ul className='ml-3' style={{listStyleType:"disc"}}>
                                  <li style={{listStyleType:"disc"}}>{countryCurrency}</li>
                                  <li style={{listStyleType:"disc"}}>USDT</li>
                              </ul>
                          </li>
            
                          <li>All proceeds are paid into your USDT wallet and withdrawals made in other currencies would first need to be exchanged to the specific currency (exchange fee is applicable) before a withdrawal can be processed.</li>
                          <li>Withdrawal requests can take from 10-14 working days to be paid. Payments will be made on Monday - Friday between 08:00 and 17:00 (CAT).</li>
                          <li>USDT payments will be made to a USDT TRON OR USDT ERC-20 Wallet Address</li>
                          <li>Please note that all capital withdrawals require a 30-day notice before the product expires. To proceed with a capital withdrawal, please send your notice to capitalwithdrawal@c1fs.co.za.</li>
                      </ol>  

                  </div>
                  {!hideWithdrawNowButton ?
                  <div className='m-auto text-center'>
                    <button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center'>Withdraw Now</button>
                    <button onClick={(e) => {setViewHistory(!viewHistory)}} className='c1-primary-btn mt-5 text-center ml-2'>{!viewHistory ? 'View Withdrawals' : 'Hide Withdrawals'}</button>
                  </div>
                  : 
                  <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 mr-2 text-blue-500 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  
              </div>

          </div>
        

      
      </div>

      {viewHistory ?
      <div>
      <hr />

      <div className="container mx-auto px-2 pt-8 pb-8">

        {modalDisplay ? 
            <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} title="Withdraw" />
            : openBlockedModal ? 
            <BlockedWithdrawalModal modalDisplay={openBlockedModal} closeModal={closeModalWithdrawBlocked} title="Unable To Withdraw" description="Unfortunately it appears that you are trying to withdraw outside of the withdrawal period which is from the 1st to the 7th of each month. If you have any questions, please contact info@c1fs.co.za." />
            : ''
        }
        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">History</h1>

        <div className="container-md mx-auto px-4">


            <div className="container-md px-4 primary-br-2">

                <div className="">
      
                <WithdrawalContainer  />

                </div>
                
            </div>

        </div>



        </div>
        </div> : ''}
    </div>
  )
}

export default Withdraw