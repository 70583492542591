import React, { useState } from 'react'

import PropTypes from 'prop-types'
import PopoverTour from '../../Modals/Tours/PopoverTour';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faQuestionCircle, faPlus, faHistory, faUser } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'

function TransactionHandleToModal({modalOTP, countryCurrency, userid=null}) {

    const [toValue, setToValue] = React.useState('');
    const [tourObject, setTourObject] = React.useState({});
    const [showPopover, setShowPopover] = useState(false);
    const [tokenCurrency, setTokenCurrency] = useState(false);

    const currencyChange = (to) => {
        modalOTP["to"] = to;
        setToValue(to);
    };

    const getHelperFunction = async() => {
        var tourObj = { 
                element: '.to-currency-tour', 
                popover: { 
                    title: 'Exchange To Currency', 
                    description: 'Select the currency to exchange to. Once the exchange is approved by the admin, this currency will be added to your wallet.',
                    side: "left",
                    align: 'start',
                    allowClose: true,
                } 
            };
          
            setTourObject(tourObj);
            setShowPopover(true);
        
    };

    const getUserTokenCurrency = async() => {
        var userObj = {
            userid: userid,
            "currencyTitle": "c1t"
          }
          await axios.post(process.env.REACT_APP_BASE + "api/user-currency-acceptable", userObj).then(async res =>{
            if(res.data.status === 200)
            {
    
                setTokenCurrency(true)
            }
          });
    
    }

    if(userid)
        getUserTokenCurrency()
  return (
    <div>
         {showPopover && <PopoverTour popupInfo={tourObject} />} {/* Render the PopoverTour conditionally */}
        <div className="mb-4">
            <div>
                <button onClick={() => getHelperFunction()} className="text-white text-sm underline hover:text-primary-color">
                    Need Help <FontAwesomeIcon icon={faQuestionCircle} className='ml-1' />
                </button>
            </div>
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                To Currency
            </label>
            <select value={toValue} onChange={(e) => currencyChange(e.target.value)} className="to-currency-tour form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                <option value="">Select To Currency</option>
            {modalOTP['from'] !== 'usdt' ?
                <option value="usdt">USDT</option>
                :
                ''}
                 {modalOTP['from'] !== 'zar' ?
                <option value="zar">{countryCurrency}</option>
                :
                ''}
                { modalOTP["type"] === "exchange" && tokenCurrency?
                  <option value="c1t">C1 Token</option>
                  : ''
                }

                {modalOTP['from'] !== 'btc' ?
                <option value="btc">BTC</option>
                :
                ''}
            </select>
        </div>

    </div>
  )
}

TransactionHandleToModal.propTypes = {
    modalOTP:PropTypes.any,
    countryCurrency:PropTypes.any,
}

export default TransactionHandleToModal