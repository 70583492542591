import React from 'react';
import Snowfall from 'react-snowfall';

import C1Holiday from './c1_holiday.png'

import ball1 from './ball1.png'
import ball2 from './candy1.png'
import ball3 from './tree1.png'
const snowflake1 = new Image();
snowflake1.src = ball1;

const snowflake2 = new Image();
snowflake2.src = ball2;

const snowflake3 = new Image();
snowflake3.src = ball3;

const snowImages = [snowflake1, snowflake2, snowflake3];

function PromotionBox({openPromotionModal}) {

  return (
    <div className="container border-gray-300 mx-auto relative bg-opacity-30  backdrop-filter backdrop-blur-sm p-4 rounded-md">
      <Snowfall
        // Changes the snowflake color
        color="white"
        // Stop snowfall within this component
        snowflakeWidth={80}
        radius={[3.0, 30.0]}
        stop
        snowflakeCount={15}
        images={snowImages}
      />
      <div className="border  p-4 flex items-center relative bg-opacity-60 backdrop-filter backdrop-blur-md rounded-md">
        <div className="w-1/5">
          {/* Image icon on the left */}
          <img src={C1Holiday} alt="Icon" className="w-full h-auto" />
        </div>
        <div className="w-4/5 ml-4">
          {/* Heading and Text on the right */}
          <h2 className="text-2xl font-semibold text-center"><u>Dear Valued Clients</u></h2>
          <br />
          <p className='text-center text-m'>We would like to inform you that our offices will be closed from the 22nd of December 2023. </p>

          <p className='text-center text-m'> Despite the closure, our dedicated support team will remain available throughout the festive season to ensure prompt and efficient assistance. </p>
          
          <br />

          <p className='text-center text-m'>Wishing you a joyful holiday season.</p>

        </div>
      </div>
    </div>
  );
}

export default PromotionBox;
