import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({userid}) {

    const [loanInfo, setLoanInfo] = useState(null)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };
    
        fetchData();
    }, [userid]); 

    const getUserLoanOptions = async() => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-loan-info",userObj).then(async (res)=>{
            console.log("User Loan Info: ", res.data)
            if(res.data.status === 200)
            {
                setLoanInfo(res.data.data)
            }
        });
    }
  return (
    <div id="laon-info-text" className="container-md px-4 pl-6">
        <div className="w-full">
        
            <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                <div className="mb-4">
                    <h1 className="title text-white text-center mb-2">Stable Fund Loan</h1>

                    {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                    <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">
                        <h2 className="text-2xl font-semibold text-gray-400 mb-4">Discover the Power of Stable Fund Loan: A Gateway to Amplify Your Investment!</h2>
                        <p className="mb-4">Exciting news from the financial frontier — our Stable Fund Loan is here to revolutionize the way you invest! Imagine turning your loan into an asset that not only serves your immediate needs but also grows over time. That's the opportunity lying in wait for you!</p>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">How Does It Transform Your Investment?</h3>
                        <ul className="list-disc pl-5 mb-4">
                            <li><strong>Immediate Benefits:</strong> Deposit an amount and instantly access 70% of your funds to use as you see fit. The remaining 30% is our magic ingredient, diligently working to grow your wealth.</li>
                            <li><strong>Strategic Growth:</strong> The 30% investment is designed to flourish, covering the 70% you've utilized. Within 5 years or 60 months, this strategy ensures your loan is fully paid off, thanks to the proceeds from our thoughtful investment.</li>
                            <li><strong>Interest to Invest:</strong> With an annual interest rate of just 5% on the remaining loan amount, your investment works smarter, not harder, in achieving monumental growth.</li>
                            <li><strong>Full Circle Financing:</strong> At the end of the 5-year journey, not only is your loan completely paid off, but your initial deposit is fully returned. You get back 100% of your funds, along with the peace of mind that your investment has been growing all along.</li>
                        </ul>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">Effortless Investing with Optimal Returns</h3>
                        <p className="mb-4">Our aim is to simplify your path to financial prosperity. The Stable Fund Loan is more than just a loan; it's a strategic investment that expands your financial boundaries effortlessly.</p>
                        
                        <h3 className="text-xl font-semibold text-gray-400 mb-3">Are You Ready to Elevate Your Investment?</h3>
                        <p>Join us today and let your funds achieve more than you could imagine. With the Stable Fund Loan, your financial aspirations are not just dreams; they're an achievable reality. Start now and witness the transformative power of your investment.</p>
                    </div>
                    : ''}
                    
                    <div>
                        <div className="p-2">
                            <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                        </div>  
                        <div className="p-2">
                            <span >Future Payout Amount (USDT): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInUSDT : "N/A"}</span>
                        </div>  
                        <div className="p-2">
                            <span >Future Payout Amount (ZAR/NAD): </span><span className="primary-color">{loanInfo ? loanInfo.totalPayoutInRand : "N/A"}</span>
                        </div>  
                        

                        <div className="flex items-center justify-between">
                            <a href="./my-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                View My Loan Products
                            </a>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
  )
}

export default LoanHistory