import React, { useState } from 'react'
import LoanCalculator from './calculator'
import LoanHistory from './productHistory'
import { UserContext } from '../../../../UserContext'

function LoanProduct() {

    const{auth,user} = React.useContext(UserContext)

    const [userid, setUserid] = useState(null)
  
    React.useEffect(()=>{
      
      if(auth && user)
      {
        setUserid(user.user.userid)
      }
  
    },[auth])
  return (
    <div className='p-4'>
        
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 p-4 mt-4">

            <LoanHistory userid={userid} />
            
            <LoanCalculator userid={userid} />
    
        </div>
    </div>
  )
}

export default LoanProduct