import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from 'axios';
import { UserContext } from "../../../UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCog, faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons'

function RightMenu() {
  const { auth, user } = React.useContext(UserContext);
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [userInfo, setUserInfo] = useState('');
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility

  React.useEffect(() => {
    if (auth && user) {
      var userObj = {
        userid: user.user.userid
      }
      setUserInfo(user.user);

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-notifications`, userObj).then((res) => {
        if (res.data.status == 200) {
          setNotificationCounter(res.data.total);
        }
      });
    }
  }, [auth]);

  const logout = async () => {
    var options = {
      isAdmin: true,
      userid: userInfo.userid
    };
    await axios.post(process.env.REACT_APP_BASE + "api/logout", options, { withCredentials: true }).then((res) => {
      window.location.href = "/login";
    });
  }

  return (
    <div className="items-center justify-end md:flex md:flex-1 lg:flex lg:flex-1 lg:w-0"
    onMouseEnter={() => setShowDropdown(true)}
    onMouseLeave={() => setShowDropdown(false)}
    >
      <div
        className="relative"
       
      >
        <button
          className="p-2 text-white-600 text-center border border-transparent rounded c1-primary-menu-hover transition-colors duration-300"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <FontAwesomeIcon id="profile" icon={faUserCircle} size="xl" />
          {/* <ReactTooltip
            anchorId="profile"
            place="bottom"
            variant="info"
            content="Profile"
          /> */}
        </button>

        {showDropdown && (  
          <div className="absolute right-0 w-48 bg-black border primary-border rounded-md shadow-lg" style={{marginTop: "-10px"}} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
            <a
              href="/settings"
              className="block px-4 py-2 text-sm text-light c1-primary-menu-hover"
            >
              Settings
            </a>
            <a
              href="/notifications"
              className="block px-4 py-2 text-sm text-light hover:primary-border c1-primary-menu-hover"
            >
              Notifications ({notificationCounter})
            </a>
            {/* <a
              href="/reports"
              className="block px-4 py-2 text-sm text-light hover:primary-border c1-primary-menu-hover"
            >
              Reports
            </a>
            <a
              href="/documents"
              className="block px-4 py-2 text-sm text-light hover:primary-border c1-primary-menu-hover"
            >
              Documents
            </a> */}
            {userInfo && userInfo.u_status === 'A' && userInfo.fica_status === 'A' ?
              <a
                href="/my-packages"
                className="block px-4 py-2 text-sm text-light hover:primary-border c1-primary-menu-hover"
              >
                My Products
              </a>
            : ''} 
            <button
             onClick={()=> logout()}
              className="block px-4 py-2 text-sm text-light hover:primary-border c1-primary-menu-hover"
            >
              Log Out
            </button>

          </div>
          )}
      </div>
    </div>
  );
}

export default RightMenu;
