import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import authenticationLogo from "../../assets/uploads/logo/c1logo.png";


import { CiMail } from "react-icons/ci";

import Privacy from "../../assets/attachments/Privacy.pdf"
import Terms from "../../assets/attachments/Terms.pdf"
import Risk from "../../assets/attachments/Risk.pdf"

function ForgotPassword() {

  const[email, setEmail] =  React.useState('');
  const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)

  const submitButton = async() => {
   
    await axios.post(process.env.REACT_APP_API_BASE_URL + `/forgot-password`,{email: email}).then((res)=>{
           
      if(res.data.status == 200){
        // toastr.options.onHidden = function () {
        //   window.location.href = "/login"
        // }

        toast.success('Email has been successfully sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setAlreadySentFirstEmail(true)

      }else{
      
        toast.error('Failed to send email', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      }

    })

  }

  const resendEmail = async () => {

    await axios.post(process.env.REACT_APP_BASE + "api/forgot-password-alternative",{email: email}).then(async res =>{

      if(res.data.status == 200){
        // toastr.options.onHidden = function () {
        //   window.location.href = "/login"
        // }
    
        toast.success('Email has been successfully sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setAlreadySentFirstEmail(true)

      }else{
      
        toast.error('Failed to send email', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      }
         
    });

  }

  const updateEmail = async (email) => {
    setEmail(email);
  }

  return (

    <React.Fragment>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className="min-h-screen flex justify-center items-center">
        {/* Background container to hold the blurred background image */}
        <div className="absolute inset-0 bg-cover bg-center z-0" ></div>

        {/* Login container to hold the login form */}
        <div className=" p-8 max-w-sm mx-auto shadow-lg z-10 relative authentication-container">
          <img src={authenticationLogo} alt="c1_logo" className="authentication-container-img" />
          <h2 className="text-center text-2xl font-bold mb-2 mt-4">Forgot Your Password?</h2>
          <h3 className="text-center text-sm text-white mb-6">Don't worry we've got you covered!</h3>

          {/* Change Email */}
          <form className="space-y-4" action="" method="post">

            {/* Email input with icon */}
            <div className="relative flex items-center">
              {/* Icon */}
              <CiMail className="absolute left-3 authentication-highlight-color"  size={18} />

              {/* Input */}
              <input 
                type="text" 
                name="email" 
                onChange={(e) => updateEmail(e.target.value)} 
                value={email}
                placeholder="Enter Email" 
                required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" 
              />
            </div>

            {/* Submit Button */}
            <input type="submit" onClick={(e) => {e.preventDefault(); submitButton(e)}} 
              value="Submit" 
              class="w-full bg-gradient-to-r from-blue-500 to-blue-400 text-white rounded-full py-2 font-semibold transition duration-300 hover:bg-blue-600 cursor-pointer authentication-button" />

            {alreadySentFirstEmail ?
              <p className="text-sm font-semibold mt-2 pt-1 mb-0 text-white">Have not yet received your emai?<button onClick={(e) => {resendEmail(e)}} className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"> Resend Email</button></p>
            : ''}          
          </form>

        </div>

        {/* Footer */}
        <div className="absolute bottom-0 left-0 w-full bg-transparent text-center text-gray-500 py-2">
          &copy; 2024 C1 FS. All Rights Reserved. | 
          <a href="mailto:info@c1fs.co.za" target="BLANK" className="hover:underline" >Support</a> | 
          <a href={Privacy} target="BLANK" className="hover:underline" >Privacy Policy</a> | 
          <a href="https://c1fs.co.za/" target="BLANK" className="hover:underline" >Our Website</a>
        </div>

      </div>

    </React.Fragment>

  )

}

export default ForgotPassword