import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PaymentModal({userid, loanInfo=null, showPaymentPopupModal, closeDepositModal}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #226A5E',
      };

      const [steps, setSteps] = useState(1)
      const [paymentAmount, setPaymentAmount] = useState(0)
      const [loading, setLoading] = useState(false)
      const [otpPin, setOTPPin] = useState('')
      

      const onPaymentAmountChange = async(amount) => {
        setPaymentAmount(amount)
      }

      const triggerCloseModal = async() => {
        setSteps(1);
        closeDepositModal()
      }

      const makeLoanPayment = async() => {
        setLoading(true)

        var otpObj = {
            userid: loanInfo.userid,
            otp: otpPin
        }

        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
      
            if(res.data.status !== 200)
            {
                
                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    await sendOTP();

                return false;
            }else{
         
            
                return true;
            }
        });

        if(!checkOtp)
        {
            setLoading(false)
            return false;
        }

           // Let check their balance first
            var balanceObject = {
                userid: loanInfo.userid,
                currency: loanInfo.currency,
              }
          
              var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject).then(async res => {
          
                if(res.data.status === 200)
                {
                    if (parseFloat(res.data.total) < paymentAmount) {
                        toast.error('Insufficient Wallet Balance!', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                
          
                
                        return false;
                      } else {
                        if (parseFloat(paymentAmount) > loanInfo.amount_remaining) {
                            toast.error('Payment Amount More Than Remaining Loan Amount!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                              });
                              return false;
                        }else
                            return true
                      }
                }else{
                    return false;
                }
               
          
              })
          
              if (!checkBalance)
              {
                setLoading(false)
                return false;
              }

              
     
              var loanPaymentObj = {
                userid: loanInfo.userid,
                loanid: loanInfo.loan_id,
                paymentAmount: paymentAmount
              }
              var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/make-loan-payment", loanPaymentObj).then(async res => {
          
                if(res.data.status === 200)
                {
            
                        toast.success('Payment Made Successfully!', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });

                        setTimeout(() => {window.location.reload(); }, 200);
                
          
                }else{
                    toast.error('Unable To Make Payment Right Now!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                      });

                      setLoading(false)
                }
               
          
              })
              
      }


      const sendOTP = async () => {

        var otpObj = {
            userid: loanInfo.userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
 
            if(res.data.status == 200)
            {
            
                toast.success('OTP Pin Successfully Sent!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

            }else{
            
                toast.error('Unable To send OTP!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
            
        })

    }

      

  return (
    <div>
           <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                />
            {showPaymentPopupModal ?
        <Modal
            open={showPaymentPopupModal}
            onClose={closeDepositModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"

            className="form-no-background"
          >
            <Box sx={{
              ...style, width: 400,
              background:
              "	rgba(5,0,5,0.8)"
            }}
            >
                {steps === 1 ?
                <div>
                    <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>{loanInfo.loan_id}</h2>

                    <p id="parent-modal-description" className=" mb-4">
                    Please note that the payment will be deducted from the amount in your wallet balance. Please click on the continue button to make payment.
                    </p>

                    <Button style={{    
                    background: "#040E18",
                    borderRadius: "70px",
                    border: "2px solid #226A5E",
                    color: "#fff"
                    }} className='c1-primary-btn'
                    onClick={() => setSteps(2)}
                    >
                    Continue
                    </Button> 

                    <Button style={{    
                    background: "#040E18",
                    borderRadius: "70px",
                    border: "2px solid red",
                    color: "#fff"
                    }} 
                    onClick={triggerCloseModal} >
                    Close
                    </Button> 
                </div>
                : steps === 2 ?
                <>
                {loading ? 
                <p>Loading...</p>
                :
                <div>
                    <div className="mb-4">
                        <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="username">
                            Payment Amount
                        </label>
                        <div className="flex items-center">
                            <input type="number" step="0.01" min="0" value={paymentAmount} onChange={(e) => { onPaymentAmountChange(e.target.value); }} className="rounded-r shadow appearance-none theme-input-border rounded-l w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline mr-2" />
                        </div>
                    </div>

                    <div className="mb-4">
                                
                                <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                                    OTP PIN 
                                </label>
                                <input type="text" value={otpPin} onChange={(e) => {setOTPPin(e.target.value)}} className="form-control shadow appearance-none border theme-input-border w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline form-no-background" />
                                </div>
                    <Button style={{    
                    background: "#040E18",
                    borderRadius: "70px",
                    border: "2px solid #226A5E",
                    color: "#fff"
                    }} className='c1-primary-btn' 
                    onClick={() => makeLoanPayment()}
                    >
                    Make Payment
                    </Button> 

                    <Button style={{    
                    background: "#040E18",
                    borderRadius: "70px",
                    border: "2px solid red",
                    color: "#fff"
                    }} 
                    onClick={closeDepositModal} >
                    Close
                    </Button> 
                </div>
                }
                </>
                :''}
      
            </Box>
          </Modal>
          : ''}
    </div>
  )
}

export default PaymentModal