
import React from 'react'

import SingleNotification from './SingleNotification'
import NotificationsContainer from '../Containers/Notifications/NotificationsContainer'

function AllNotifications({filter, showAll}) {
  return (
    <div>    
      <NotificationsContainer  filter={filter} showAll={showAll}  />
      {/* <SingleNotification /> */}
    
    </div>

  )
}

export default AllNotifications