import React, { useState } from 'react';

function CopyToClipboardButton({ text, tokenName='' }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset copied state after 3 seconds
    });
  };

  return (
    <button onClick={copyToClipboard} className="copy-button btn c1-primary-btn-sm mt-1 mb-2">
      {isCopied ? 'Copied!' : `Copy ${tokenName} Address`}
    </button>
  );
}

export default CopyToClipboardButton;
