import React, {useState, useEffect} from "react";

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import { UserContext } from "../../UserContext";

// Assets
import Logo from "../../assets/uploads/logo/c1logo.png";

// Wallet
import Wallet from "../Wallet/Wallet"

// Header menu
import Menu from "./Menu/Menu";
import RightMenu from "./Menu/RightMenu";


import axios from 'axios'

import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


const transactions = [
  {
    name: 'Deposit',
    href: '/deposit',
    icon: ChartBarIcon,
  },
  {
    name: 'Withdraw',
    href: '/withdraw',
    icon: ChartBarIcon,
  },
  { 
    name: 'Exchange', 
    href: '/exchange', 
    icon: ChartBarIcon,
  },
  { 
    name: 'History', 
    href: '/transactions', 
    icon: ChartBarIcon,
  },
  
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {

  const{auth,user} = React.useContext(UserContext)
  const[userInfo, setUserInfo] =  React.useState([]);
  const[products, setProducts] =  React.useState([]);
  const[userCompany, setUserCompany] =  React.useState(null);

  React.useEffect( () => {
  
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        setUserInfo(user.user)
        setUserCompany(user.user.company)
        assignProductsToHeader(user)

      }
    }
  }, [auth])

  var assignProductsToHeader = async(user) => {

    var userObj = {
      id: user.user.id
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}get-user-saved-products`,userObj).then((res)=>{

      var productsToSet = [];
      var newProductArray = []
      var newproducts = res.data.data
      var newObject = {}

      for (let productid of newproducts) {
   
        if(productid === '15')
        {
          newObject = {
            name: 'Transaction Accumulation Fund',
            href: '/accumulation-fund',
            icon: ChartBarIcon,
          }
        
        }else if(productid === '22' || productid === '23' || productid === '24' || productid === '25')
        {
          newObject = {
            name: 'Capital Interest Vault',
            href: '/products/capital-interest-vault',
            icon: ChartBarIcon,
          }
      
        }else if(productid === '26' || productid === '27' || productid === '28')
        {
          newObject = {
            name: 'Savings Bundle',
            href: '/products/savings-bundle',
            icon: ChartBarIcon,
          }
        
        }else if(productid === '29' || productid === '30' || productid === '31' || productid === '32')
        {
          newObject = {
            name: 'Tokenized Index',
            href: '/products/tokenized-index',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '33' || productid === '34' || productid === '35')
        {
          newObject = {
            name: 'Monetary Stability Fund',
            href: '/products/monetary-stability-fund',
            icon: ChartBarIcon,
          }
         
        }else if(productid === '36' || productid === '37' || productid === '38' || productid === '39')
        {
          newObject = {
            name: 'Accumalation Vault',
            href: '/products/accumalation-vault',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '40')
        {
          newObject = {
            name: 'Fund Builder',
            href: '/products/fund-builder',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '41')
        {
          newObject = {
            name: 'Solar Flare',
            href: '/products/solar-flare',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '42')
        {
          newObject = {
            name: 'Stella Nova',
            href: '/products/stella-nova',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '43')
        {
          newObject = {
            name: 'Galaxy Pulse',
            href: '/products/galaxy-pulse',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '44')
        {
          newObject = {
            name: 'Supernova Spark',
            href: '/products/supernova-spark',
            icon: ChartBarIcon,
          }
       
        }else if(productid === '45' || productid === '46' || productid === '47')
        {
          newObject = {
            name: 'Wealth Creator',
            href: '/products/wealth-creator',
            icon: ChartBarIcon,
          }
         
        }else if(productid === '48')
        {
          newObject = {
            name: 'Stable Fund Loan',
            href: '/product/stable-fund-loan',
            icon: ChartBarIcon,
          }
         
        }else if(productid === '49')
        {
          newObject = {
            name: 'Wealth Loan 2',
            href: '/product/loan-calculator2',
            icon: ChartBarIcon,
          }
         
        }else if(productid === '50')
        {
          newObject = {
            name: 'LTV Loan',
            href: '/product/ltv-loan',
            icon: ChartBarIcon,
          }
         
        }
     
        const isObjectIncluded = newProductArray.some((obj) => obj.name === newObject.name);

        if (!isObjectIncluded) {
    
          newProductArray.push(newObject);
        }
      }

      
      setProducts(newProductArray)

      // if(user.user.company === '2' || user.user.company === null)
      // {
      //   setProducts([
      //     {
      //       name: 'Transaction Accumulation Fund',
      //       href: '/accumulation-fund',
      //       icon: ChartBarIcon,
      //     },
        
      //   ])
      // }else{
      //   setProducts([
      //     {
      //       name: 'Capital Interest Vault',
      //       href: '/products/capital-interest-vault',
      //       icon: ChartBarIcon,
      //     },
      //     {
      //       name: 'Savings Bundle',
      //       href: '/products/savings-bundle',
      //       icon: ChartBarIcon,
      //     },
      //     {
      //       name: 'Monetary Stability Fund',
      //       href: '/products/monetary-stability-fund',
      //       icon: ChartBarIcon,
      //     },{
      //       name: 'Synthetic Assets',
      //       href: '/products/synthetic-assets',
      //       icon: ChartBarIcon,
      //     },
      //     {
      //       name: 'Tokenized Index',
      //       href: '/products/tokenized-index',
      //       icon: ChartBarIcon,
      //     },
        
      //   ])
  
      // }

    })

  }

  const logout = async ()=>{

    var options =
    {
      isAdmin:true,
      userid: userInfo.userid
    }
    await axios.post(process.env.REACT_APP_BASE + "api/logout",options,{withCredentials:true}).then((res)=>{
      // window.location('/login-register');
     
      window.location.href="/login"
      
    })
  }

  return (
    <Popover className="relative">
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/dashboard">
              <span className="sr-only">C1 FS</span>
              <img
              style={{ height: '170px' }} // Customize this value as needed
                //className="h-16 w-auto sm:h-16" // Updated height class
                src={Logo}
                alt=""
              />
            </a>
          </div>
          {/* <div className="-my-2 -mr-2 md:hidden">
      
            <Popover.Button className="inline-flex form-no-background items-center justify-center rounded-md p-2 text-white-400 hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-inset ">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
           
            </Popover.Button>
          </div> */}

          <Menu products={products} transactions={transactions} userInfo={userInfo}   />
          <Wallet  />
            
          <RightMenu />
          
        </div>
      </div>

      {/* <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      > */}
        {/* <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"  style={{
            zIndex: 9999, 
          }}>
            
          <div className="divide-y-2 divide-gray-50 rounded-lg primary-border bg-black text-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                <img
                className="h-8 w-auto sm:h-10"
                src={Logo}
                alt=""
              />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md form-no-background p-2 text-white-400 hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
         
                    <a
                      
                      href="/dashboard"
                      className="-m-3 flex items-center rounded-md p-3 primary-bb-1 hover:bg-gray-50"
                    >
                      <ChartBarIcon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">Dashboard</span>
                    </a>
             
                </nav>
              </div>
              {userInfo.fica_status === 'A' && userInfo.u_status === 'A'?
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {products.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex primary-bb-1 items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
              : ''}
              {userInfo.fica_status === 'A' && userInfo.u_status === 'A'?
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {transactions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center primary-bb-1 rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
              : ''}
          
              <div className="mt-6">
                <nav className="grid gap-y-8">
            
                    <a
                      href="/reports"
                      className="-m-3 flex primary-bb-1 items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <ChartBarIcon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">Archives</span>
                    </a>

                    {/* <a
                      href="/documents"
                      className="-m-3 flex primary-bb-1 items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <ChartBarIcon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">Documents</span>
                    </a> 
                </nav>
              </div>
          

              <div className="mt-6">
                <nav className="grid gap-y-8">
         
                    <a
                      
                      href="/settings"
                      className="-m-3 flex items-center primary-bb-1 rounded-md p-3 hover:bg-gray-50"
                    >
                      <ChartBarIcon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white-900">Settings</span>
                    </a>
             
                </nav>
              </div>
   
            </div>
            <div className="space-y-6 py-6 px-5">
              <div>
                <button
                  onClick={logout}
                  className="flex w-full items-center justify-center rounded-md border border-transparent c1-primary-btn px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Log Out
                </button>

              </div>
            </div>

          </div>
        </Popover.Panel> */}
      {/* </Transition> */}
    </Popover>
  )
}
