import React, { useEffect, useState } from 'react';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

function PopoverTour({ popupInfo = {} }) {

  const driverObj = driver();
    driverObj.highlight(popupInfo);

  return <div></div>;
}

export default PopoverTour;
