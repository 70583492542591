import React, {useState, useEffect} from "react";
import {Page, Text, Font, Image, View, Document, StyleSheet} from '@react-pdf/renderer';

import c1Logo from '../../assets/uploads/logo/c1logo.png'


function PDFProducts({title, name, info, userid, date, currency}) {

  // const currency = {
  //     '1': 'USDT',
  //     '2': 'ZAR',
  //     '3': 'BTC'
  // }


  const status = {
    '1': 'Approved',
    '2': 'Declined',
    '0': 'Pending'
  }



  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
      
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 26,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    pdfHeader: {
        display: 'inline-block'
    },
    author: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 14,
      margin: 12,
      fontFamily: 'Oswald',
      display: 'inline'
    },

    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },

    image: {
      marginVertical: 15,
      marginHorizontal: 220,
      height: "100px",
      width: "100px",
      textAlign: "center",
      display: 'inline-block'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },

    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },

    inlineBlock: {
      display: "flex",
      flexDirection: "row",
      fontSize: 14,
    },
    inlineBlock2: {
      // marginTop: "20px",
      backgroundColor: "#226A5E",

      color: "white",
      display: "flex",
      flexDirection: "row",
      fontSize: 12,

      border: "1px solid black",
    },
    inlineBlock3: {
      backgroundColor: "#ebf7fa",
      color: "black",
      // border: "1px solid black",

      display: "flex",
      flexDirection: "row",
      fontSize: 9,
    },

    tableBlock: {
      border: "1px solid black",
    }
  });

      

  return (
    <Document>
      <Page style={styles.body}>
        <Image
          style={styles.image}
          src={c1Logo}
        />
        <Text style={styles.title}>C1 FS</Text>

        <Text style={styles.author}>{title}</Text>
        <View style={styles.inlineBlock}>
          <View style={{ flex: 1 }}>
            <Text >{name}</Text>
              <Text >Customer ID: {userid}</Text>
              <Text >Date: {date}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text>C1 FS (Pty) Ltd</Text>
            <Text>The Old Tannery</Text>
            <Text>Block C</Text>
            <Text>Hermon Road</Text>
            <Text>Wellington</Text>
            <Text>7654</Text>
          </View>
        </View>
        
        {info.map((transaction,key) => (
          <View style={styles.tableBlock}>
            <View style={styles.inlineBlock2}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >Date</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >Name</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                <Text >Description</Text>
              </View>
              <View style={{ flex: 2, padding: "5px", textAlign: "center"  }}>
                <Text >Plan ID</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                <Text >Amount</Text>
              </View>
              {/* <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                <Text >Total</Text>
                </View> */
              }
            </View>
        
            <View style={styles.inlineBlock3} key={key} wrap={false}>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >{transaction.invest_date}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                <Text >{name}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                <Text >Contribution Paid {`Expire: ${transaction.expire_date}`}</Text>
              </View>
              <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center"  }}>
                <Text >{transaction.planid}</Text>
              </View>
              <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                <Text >{currency[transaction.currency] + transaction.amount}</Text>
              </View>
              {/* <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                <Text >{currency[transaction.currency] + transaction.amount}</Text>
                </View> */
              }
            </View>
            {transaction.interests.map((interest,key) => (
                  
              <View style={styles.inlineBlock3} key={key} wrap={false}>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text >{interest.date}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center" }}>
                  <Text >{name}</Text>
                </View>
                <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                  <Text >Interest Paid</Text>
                </View>
                <View style={{ flex: 2, display: "block", padding: "5px", textAlign: "center"  }}>
                  <Text >{interest.planid}</Text>
                </View>
                  <View style={{ flex: 1, padding: "5px", textAlign: "center"  }}>
                  <Text >{currency[transaction.currency] + interest.amount.toFixed(2).toString()}</Text>
                </View>
                {/* <View style={{ flex: 1, padding: "5px", textAlign: "center", border: "1px solid black"  }}>
                  <Text >{currency[transaction.currency] + interest.total.toFixed(2).toString()}</Text>
                  </View> */
                }
              </View>
            ))}

          </View>
        ))}
    
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      </Page>
    </Document>
  )
}



export default PDFProducts