import PropTypes from "prop-types";

import React, {useState, useEffect} from "react";

import axios from 'axios'

import '../../index.css';

import { withRouter, Link } from "react-router-dom";

import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label, Button } from "reactstrap";

import logoGif from "../../assets/uploads/logo_gif.gif"

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/free-solid-svg-icons'
import { faWallet } from '@fortawesome/free-solid-svg-icons'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmEmail = props => {

    
  const[showRedirectOptions, setShowRedirectOptions] =  React.useState(false);
  const[userOptionSelected, setUserOptionSelected] =  React.useState(true);

  const queryParams = new URLSearchParams(window.location.search);

  const onConfirmEmail = async (message) => {
    const getUserToken = queryParams.get('userToken');

  
 
    if(getUserToken !== '' && getUserToken !== null) {
        
      var userObject = {
        userToken: getUserToken
      }
      
      axios.post(process.env.REACT_APP_BASE + "api/verify-email-token",userObject).then(async res =>{
          
        if(res.data.status == 200){
          toast.success("Verified Successfull", {
            position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
          });
                    
          setShowRedirectOptions(true)
        }
        
        else{
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
         
      });
    }
    
    else{
      toast.error("Invalid Link", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }
  }

  const changeUserType = async (type) => {
   
    setUserOptionSelected(type)

  }

  const omSubmitLogin = async (user) => {
    if(userOptionSelected)
    {
      window.location.href="/login"
    }else{
      window.location.href="/login?complete=true"
    }
  }


  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className="website-main-bg rounded-xl shadow border h-screen c1-full-height-mobile">
        
        <div className="px-6 md:h-full text-white-800">

          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
         
              <form>

                <div className="mb-4">

                  <h1 className="text-white text-center text-4xl">Confirm Email</h1>
                  <h1 className="text-white text-center">Please confirm your email address by clicking the link below.</h1>

                </div>
   
                {/* <div className="mb-6">
                  <input
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder={userOptionSelected? "Name" : "Company Name" }
                    onChange={(e) => {updateName(e.target.value)}}
                   
                    value={name}
                  />
                </div> */}

                
                {!showRedirectOptions?

                  <div className="text-center lg:text-center">

                    <button type="button" className="c1-primary-btn " onClick={(e) => {onConfirmEmail(e)}}>
                      Confirm Email
                    </button>

                  </div>
                  
                :

                  <div>
                    <div className="mb-6">

                      <div className="grid grid-cols-3 gap-2 md:grid-cols-3 lg:grid-cols-3 text-center">

                        <div onClick={(e) => {changeUserType(true)}} className={userOptionSelected? "form-no-background" : ''}>
                          <FontAwesomeIcon  icon={faSignIn} size="xl"  /><br />
                          Login
                        </div>

                        <div>Or</div>

                        <div onClick={(e) => {changeUserType(false)}} className={!userOptionSelected? "form-no-background" : ''}>
                          <FontAwesomeIcon  icon={faWallet} size="xl"  /><br />
                          Login And Fund Account Now
                        </div>

                      </div>

                    </div>

                    <div className="text-center lg:text-center">
                      <button type="button" className="c1-primary-btn " onClick={(e) => {omSubmitLogin(e)}}>
                      Submit
                      </button>
                    </div>

                  </div>

                }

              </form>
  
            </div>
            
          </div>

        </div>

      </div>

    </React.Fragment>

  );

};

export default withRouter(ConfirmEmail);

ConfirmEmail.propTypes = {
  history: PropTypes.object,
};

