import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Button } from '@mui/material'
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the FontAwesome close icon

import Advertising from '../../assets/uploads/ERC20.png'

import decNews from '../../pages/Reports/December_Newsletter.pdf'

function AdervertisingModal3({ user, displayModal, closeAdvertiseModal }) {

    const [advertisingValue, setAdvertisingValue] = React.useState(false);
    const [optionadvertisingValue, setOptionAdvertisingValue] = React.useState(false);

    const changedAdvertisingValue = async() => {

        setAdvertisingValue(!advertisingValue)
    }

    const changedOptionAdvertisingValue = async() => {
      
        setOptionAdvertisingValue(!optionadvertisingValue)
    }

    const submitSaveAndClose = async() => {

        var updatemetaObj = {
            userid: user.userid,
            meta_key: "advertising_3",
            meta_value: false
        }
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/update-meta",updatemetaObj).then( async(res)=>{
            
            await closeAdvertiseModal();
        })
      
    }

    const saveOptionAndClose = async() => {

        var updatemetaObj = {
            userid: user.userid,
            meta_key: "advertising_2",
            meta_value: false
        }
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/update-meta",updatemetaObj).then( async(res)=>{

            var updateOptionObj = {
                userid: user.userid,
                settingValue: "true",
            }

            const updateOption = await axios.post(process.env.REACT_APP_BASE + "api/update-fund-manager-option",updateOptionObj)
            
            await closeAdvertiseModal();
        })
      
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%', // Set the width to 95% of the viewport
        maxWidth: '450px', // Set the maximum width for larger screens
        maxHeight: '90vh', // Set the maximum height to 90% of the viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #226A5E',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    };

    return (
        <div>
            <Modal
                open={displayModal}
                onClose={closeAdvertiseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className="form-no-background"
            >
                <Box sx={{
                    ...style,
                }}>
                    {/* Close button (X button) */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={closeAdvertiseModal}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>

                    <div className="external_deposit_modal">
                        <h1 className="text-center font-weight-bold">C1 FS December Newsletter!</h1>
                        {/* <img src={Advertising} /> */}
                        <br />

                        <p>{`Important updates are in our latest Newsletter. Please review and acknowledge changes for the holiday season and new year.Download Now or visit the Documents page in the menu header.`}</p>
                         <br />
                 
                  
                        <a rel="noreferrer" target="_blank" href={decNews} style={{    
                            background: "#040E18",
                            borderRadius: "70px",
                            border: "2px solid #226A5E",
                            color: "#fff"}} className='c1-primary-btn' >Download Now</a>

                         {/* {!advertisingValue || advertisingValue === 'false' ?
                         <div>
                         <input type="checkbox" className="form-control primary-color" value={advertisingValue} onChange={(e) => {changedOptionAdvertisingValue()}} />
                         <label className="primary-color">Enable this option and do not open this again.</label>
                         </div>
                         : ''} */}
        <br />
                         <br />
                         {!optionadvertisingValue || optionadvertisingValue === 'false' ?
                         <div>
                         <input type="checkbox" className="form-control primary-color" value={advertisingValue} onChange={(e) => {changedAdvertisingValue()}} />
                         <label className="primary-color">Do not open this again.</label>
                         </div>
                         : ''}

                         <br />
                        {advertisingValue || advertisingValue === 'true' ?
                        <Button style={{    
                            background: "#040E18",
                            borderRadius: "70px",
                            border: "2px solid #226A5E",
                            color: "#fff"}} className='c1-primary-btn' onClick={() => {submitSaveAndClose()}}>I Acknowledge & Close</Button>
                        :

                        ''
                        }
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

AdervertisingModal3.propTypes = {
    closeAdvertiseModal: PropTypes.func,
    user: PropTypes.any,
    displayModal: PropTypes.any,
}

export default AdervertisingModal3;
