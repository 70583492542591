import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { display } from '@mui/system';

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import PDFProducts from "../../PDF/PDFProducts";
import { PDFDownloadLink } from '@react-pdf/renderer'


function ProductsReport() {

  const[selectedReport, setDelectedReport] =  React.useState('all');
  const{auth,user} = React.useContext(UserContext)

  const [transactions, setTransactions] = useState([])
  const[name, setName] =  React.useState('');
  const[userid, setUserid] =  React.useState('');
  const[useDate, setUseDate] =  React.useState('');
  const[totalZAR, setTotalZAR] =  React.useState(0);
  const[totalUSDT, setTotalUSDT] =  React.useState(0);
  const[totalBTC, setTotalBTC] =  React.useState(0);
  const [productData, setProductData] = useState([])
  const [checkedData, setCheckedData] = useState([])
  const [currencyDisplay, setCurrencyDisplay] = useState({
    '1': 'USDT',
    '2': 'ZAR',
    '3': 'BTC',
  })

  const productname = {
    '15':   "Accumulation Fund",
    '16':   "Savings Vault",
    '17':   "Savings Vault",
    '18':   "Inflation Fund Index",
    '19':   "Inflation Fund Index",
    '20':   "Inflation Fund Index",
  }
    

  const handleRadioButtonChange = (value) => {
    setDelectedReport(value)
  }

  // const onPoductAdd = async (name, ischecked) => {
  //     if(ischecked) {
  //         items.push(item)
  //     }else{

  //     }
  //     checkedData.push()
  // }

  React.useEffect(()=>{

    if(auth && user)
    {
      getUserDetails(user.user.userid)
      setName(user.user.name + ' ' + user.user.lastname)
      setUserid(user.user.userid)
      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let newstring = ''
      if(month<10)
        newstring = '0' + month
      else
        newstring = month
      setUseDate(year + '-' + newstring + '-' + date)
      var userObj = {
        userid: user.user.userid
      }
  
      getUserBalances(user.user.userid)

      getUserProductData(user.user.userid)
      
    }
  
  },[auth])

  const getUserDetails = async (userid) => {
    var userObj = {
      userid: userid
    }
      
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
        var mainString = res.data.info.country
        if(mainString && mainString.includes("Namibia"))
        {
          setCurrencyDisplay({
            '1': 'USDT',
            '2': 'NAD',
            '3': 'BTC',
          })
        }else{
             
        }
      }
    });
  }
  
  const getUserBalances = async(userid) => {
    var userObj = {
      userid: userid
    }
      
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      { 
        setTotalZAR(res.data.currencyInfo[1].balance)
        setTotalUSDT(res.data.currencyInfo[0].balance)
        setTotalBTC(res.data.currencyInfo[2].balance)
      }
    })
  
    return true;
  }

  const getUserProductData = async (userid) => {
        
    var userObj = {
      userid: userid
    }
      
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/all-user-open-products`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {   
        setProductData(res.data.total)
      }
    })
  
    return true;
  }
  return (
    <div>
          
    <div>My <span className="primary-color">Products <FontAwesomeIcon className="pl-2" icon={faCaretDown} size="xl"  /></span></div>

    <div>
      <div className="mt-4 mb-4">

        <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={selectedReport === 'all'}  className="btn-check" name="vbtn-radio"  value="all" id="allOption" />
        <label style={{borderRadius: "70px", padding: "5px 10px", marginBottom: "20px"}} className={'mr-8' + (selectedReport === 'all' ? ' c1-primary-btn-active' : '')} htmlFor="allOption">All</label>
        {/* <input onClick={(e) => handleRadioButtonChange(e.target.value)} checked={selectedReport === 'sperate'}  type="radio" className="btn-check" name="vbtn-radio" value="sperate" id="seperateOption"/>
        <label className={'c1-primary-radio mr-8' + (selectedReport === 'sperate' ? ' c1-primary-btn-active' : '')} htmlFor="seperateOption">Separate</label> */}
      </div>
    </div>

    {selectedReport === 'sperate' ? 
      <div className='mt-4'>
        {/* {productData.map((product,key) => (
          // <p id="parent-modal-description"  className=" mb-4 text-sm">
          //     <input onClick={(e) => onPoductAdd(e.target.value, e.target.checked)} type="checkbox" key={key} value={product.planid} className='form-control text-white mr-2' />
          //      {product.planid + ' (' + productname[product.product_id] + ')'}
          // </p>
          ))} */
        }


      </div>
    : ''}
    
    {/* <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 mb-4" type="button">
        Download PDF Statement
    </button> */}

    <PDFDownloadLink document={<PDFProducts title="Product Statement" name={name} currency={currencyDisplay}  info={productData} userid={userid} date={useDate} />} fileName={"Products-" + useDate} >
      {({loading }) => (loading ? "Loading..." : <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
      Download PDF Statement
      </button>)}
    </PDFDownloadLink>

</div>
  )
}

export default ProductsReport