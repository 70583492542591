import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { withRouter, Link, useLocation } from "react-router-dom"

import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { useTable } from 'react-table'

import ProductTable from "./ProductTable";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SingeProduct() {

  const{auth,user} = React.useContext(UserContext)
  const[foundSingleProduct, setFoundSingleProduct] =  React.useState(true);
  const[productData, setProductData] =  React.useState([]);

  const[productDisplayData, setProductDisplayData] =  React.useState([]);
    
    
  let query = useQuery()
  let userToken = query.get("pid");


  React.useEffect(()=>{
  
    if(auth && user){

            
      if(userToken !== null && userToken !== undefined && userToken !== '')
      {
               
        var singleProduct = getSingleProduct(user.user.userid)

      }else{

        // window.location.href="/login"

      }
          
    }
  
  },[auth])

  const getSingleProduct = async(userid) => {
      
    var userObj = {
      userid: userid,
      pr_id: userToken
    }
      
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/single-user-open-products`,userObj).then(async (res)=>{
      if(res.data.status == 200){

        setProductDisplayData(res.data.total.interests)
        setProductData(res.data.total)
        setFoundSingleProduct(true) 
           
      }
    })

  }

  var productTitles = {
    16: 'Savings Vault',
    17: 'Savings Vault',
    15: 'Transaction Accumulation Fund',
    18: 'Inflation Counter Index',
    19: 'Inflation Counter Index',
    20: 'Inflation Counter Index',

    22: 'Capital Interest Vault',
    23: 'Capital Interest Vault',
    24: 'Capital Interest Vault',
    25: 'Capital Interest Vault',
    26: 'Savings Bundle',
    27: 'Savings Bundle',
    28: 'Savings Bundle',
    29: 'Tokenized Index',
    30: 'Tokenized Index',
    31: 'Tokenized Index',
    32: 'Tokenized Index',
    33: 'Monetary Stability Fund',
    34: 'Monetary Stability Fund',
    35: 'Monetary Stability Fund',
    36: 'Accumalation Vault',
    37: 'Accumalation Vault',
    38: 'Accumalation Vault',
    39: 'Accumalation Vault',
    40: 'Fund Builder',
    41: 'Solar Flare',
    42: 'Stella Nova',
    43: 'Galaxy Pulse',
    44: 'Supernova Spark',
    45: 'Wealth Creator',
    46: 'Wealth Creator',
    47: 'Wealth Creator',
  }

  var status = {
    '0': "Pending",
    '1': "Approved",
    '2': "Declined",
    '3': "Closed",
  }

  const data = React.useMemo(
  () => 
    // [
    //   {
    //     col1: 'Hello',
    //     col2: 'World',
    //   },
    //   {
    //     col1: 'react-table',
    //     col2: 'rocks',
    //   },
    //   {
    //     col1: 'whatever',
    //     col2: 'you want',
    //   },
    // ],
    productDisplayData,
    []
  )
    
//       amount
// : 
// 200
// createdAt
// : 
// "2023-01-19 11:33:26"
// date
// : 
// "2023-01-20"
// id
// : 
// 67
// month
// : 
// "1"
// percentage
// : 
// 10
// planid
// : 
// "#IJ-202311997107"
// productid
// : 
// "15"
// total
// : 
// 5200
// updatedAt
// : 
// "2023-01-19 11:33:26"
// userid
// : 
// "C10000004"

  const columns = React.useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'month', // accessor is the "key" in the data
      },
      {
        Header: 'Percentage',
        accessor: 'percentage'
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ],
    []
  )
    
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })


  return (
    <div className="container-md mx-auto px-4 mt-6 flex justify-center">
      <div className="flex flex-col md:flex-row">
        {/* Left Container */}
        <div className="md:w-2/8 single-product-user-info">
          {foundSingleProduct && typeof productData !== undefined ?
            <div>
              <div>
                <div className="p-2">
                  <span className="primary-color">Plan ID: </span>{productData.planid}
                </div>
                <div className="p-2">
                  <span className="primary-color">Invested Date: </span>{productData.invest_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Invested Amount: </span>{productData.amount}
                </div>
                <div className="p-2">
                  <span className="primary-color">Start Date: </span>{productData.start_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Expire Date: </span>{productData.expire_date}
                </div>
                <div className="p-2">
                  <span className="primary-color">Status: </span>{status[productData.status]}
                </div>
                <div className="p-2">
                  <span className="primary-color">Product: </span>{productTitles[productData.product_id]}
                </div>
              </div>
            </div>
            : 'No Product Found'}
        </div>
        {/* Right Container */}
        <div className="md:w-6/8 mt-4 md:mt-0 ml-4 md:ml-10 flex justify-center">
          {foundSingleProduct && typeof productData !== undefined ?
            <div className="single-product-user-table p-4">
              <div className="mt-4">
              <ProductTable columns={columns} data={productDisplayData} />
              </div>
            </div>
          : null}
        </div>
      </div>
    </div>


  );

}

export default SingeProduct;