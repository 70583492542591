import React, { useEffect, useState } from 'react';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

function TourGuide({ steps = [], showProgress = true, allowClose = false, display = true, onClose, onFinish }) {
  const [tourActive, setTourActive] = useState(true);

  useEffect(() => {
    let driverObj = null;

    if (tourActive) {
      driverObj = driver({
        showProgress: showProgress,
        allowClose: allowClose,
        steps: steps,
        onCloseClick: () => {
          // This function will be called when the user clicks the "Done" button
          // You can call your custom function here or perform any necessary actions
          if (typeof onClose === 'function') {
            onClose();
          }
          // Mark the tour as completed
          setTourActive(false);
        },
        onDestroyed: () => {
          // This function will be called when the tour is finished (all steps completed)
          // You can call your custom function here or perform any necessary actions
          if (typeof onFinish === 'function') {
            onFinish();
          }
          // Mark the tour as completed
          setTourActive(false);
        },
      });

      driverObj.drive();
    }

    // Cleanup function to reset the tour when the component unmounts
    return () => {
      if (driverObj) {
        // You can reset the driverObj here if needed
      }
    };
  }, [display, steps, showProgress, allowClose, onClose, onFinish, tourActive]);

  return <div></div>;
}

export default TourGuide;
