import PropTypes from "prop-types";

import React, {useState, useEffect} from "react";

import axios from 'axios'

import '../../index.css';

import { withRouter, Link, useParams } from "react-router-dom";

import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label, Button } from "reactstrap";

import ReCAPTCHA from "react-google-recaptcha";

import { useLocation } from 'react-router-dom';
import authenticationLogo from "../../assets/uploads/logo/c1logo.png";

// Icons
import { CiMail } from "react-icons/ci";
import { CiMobile4 } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { BiBuilding } from "react-icons/bi";
import { CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";


// Attachments
import termsPDF from "../../assets/attachments/Terms.pdf";
import popiPDF from "../../assets/attachments/C1FS_POPI.pdf"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Privacy from "../../assets/attachments/Privacy.pdf"
import Terms from "../../assets/attachments/Terms.pdf"
import Risk from "../../assets/attachments/Risk.pdf"


const Register = props => {

  let { company } = useParams()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const URLuserid = searchParams.get('userid');

  const[name, setName] =  React.useState('');
  const[last, setLast] =  React.useState('');
  const[email, setEmail] =  React.useState('');
  const[cPassword, setCPassword] =  React.useState('');
  const[password, setPassword] =  React.useState('');
  const[cell, setCell] =  React.useState('');

  const[terms, setTerms] =  React.useState(false);
  const[popi, setPopi] =  React.useState(false);

  const [reCaptcha,setReCaptcha] = useState('')

  
  const[userOptionSelected, setUserOptionSelected] =  React.useState(true);
  
  const[showFinalMessage, setShowFinalMessage] =  React.useState(false);
  const[showRegButton, setShowRegButton] =  React.useState(true);
  const[showPage, setShowPage] =  React.useState(false);
  const[referralCode, setReferralCode] =  React.useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  React.useEffect(() =>{

    runCompanyCheck()

  },[])

  const runCompanyCheck = async() => {
    try{

      const companyObj = {
        company: company,
        referral: URLuserid
      }
   
      const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-company-referral",companyObj).then(async (res)=>{
        if(res.data.status === 200)
        {
          setShowPage(true)
          setReferralCode(res.data.data.userid)
        }else{
          return window.location.href="/404"
        }
      });

    }catch(err){

      return window.location.href="/404"
      
    }
  }

  const updateName = async (name) => {
    setName(name);
  }

  const updateLast = async (last) => {
    setLast(last);
  }

  const updateEmail = async (email) => {
    setEmail(email);
  }

  const updatePassword = async (password) => {
    setPassword(password);
  }

  const updateCPassword = async (password) => {
    setCPassword(password);
  }

  const updateTerms = async (terms) => {
    setTerms(terms);
  }

  const updatePopi = async (popi) => {
    setPopi(popi);
  }

  const updateCell = async (cell) => {
    setCell(cell)
  }

  const changeUserType = async (type) => {
    setUserOptionSelected(type)
  }

  function onChangeRecaptcha(value)
  {
    setReCaptcha(value);
  }

  const subLoginDetails = async () => {

    setShowRegButton(false)  

    var showError = false;
    var errorMessage = '';
    var userType = '0'

    if(!userOptionSelected)
    {
      userType = '1';
    
    }
    // return false;

    // const userType = req.body.usertype;
    if(name == '' )
    {
      showError = true;
      errorMessage = 'Please enter First Name'
    }

    if(last == '' && userOptionSelected )
    {
      showError = true;
      errorMessage = 'Please enter Last Name'
    }

    if(!terms )
    {
      showError = true;
      errorMessage = 'Terms And Conditions is not checked'
    }

    if(!popi )
    {
      showError = true;
      errorMessage = 'POPI is not checked'
    }

    if(cell == '' )
    {
      showError = true;
      errorMessage = 'Please enter Mobile Number'
    }

    if(email == '' )
    {
      showError = true;
      errorMessage = 'Please enter Email'
    }

    if(password == '' )
    {
      showError = true;
      errorMessage = 'Please enter valid Password'
    }

    if(cPassword == '' )
    {
      showError = true;
      errorMessage = 'Please enter Valid Confirm Password'
    }

    if(reCaptcha == '' || reCaptcha == null)
    {
      showError = true;
      errorMessage = 'Google Recaptcha required'
    }

    if(showError)
    {
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    var userObj = {
      name: name,
      last: last,
      ucode: referralCode,
      terms: terms,
      popi: popi,
      cell: cell,
      email: email,
      usertype: userType,
      password: password,
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register-new-user`,userObj).then(async (res)=>{
     
      if(res.data.status == 200)
      {
        toast.success("User Created Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
     // Wait for the toast to be shown for a while before redirecting
     setTimeout(() => {
      window.location.href = "https://portal.c1fs.co.za/login"; // Change to navigate to /login
    }, 200); 
  
      }else{

        setShowRegButton(true)
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      }

    })

  }
  const togglePasswordVisibility = async() => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = async() => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div>
        {showPage ?

          <div className="min-h-screen flex flex-col">

            {/* Main Content */}
            <main className="flex-1 flex inset-0 bg-cover bg-center z-0" >
              {/* Left Sidebar */}
              <aside className="w-3/12">

              </aside>

              {/* Center Content */}
              <section className="flex-1 authentication-registration-container w-6/12">

                <img src={authenticationLogo} alt="c1_logo" className="authentication-container-img" />
                <h2 className="text-center text-2xl font-bold mb-2 mt-4">Register</h2>
                <h3 className="text-center text-sm text-white mb-6">Complete the fields below to become a valued member.</h3>
                
                {/* Registration form */}
                <form className="space-y-4" action="" method="post">

                <div className="relative flex items-center justify-center user toggle">
                  <div className="grid grid-cols-3 gap-3 md:grid-cols-3 lg:grid-cols-3 text-center">
                    <div className={userOptionSelected ? "authentication-highlight-color flex flex-col items-center" : "authentication-highlight-color-hover flex flex-col items-center"} onClick={(e) => { changeUserType(true) }}>
                      <CiUser size="25" className="user-company-icon" />
                      <span className="mb-1">User</span> {/* Decrease the bottom margin */}
                    </div>

                    <div>Or</div>

                    <div className={!userOptionSelected ? "authentication-highlight-color flex flex-col items-center" : "authentication-highlight-color-hover flex flex-col items-center"} onClick={(e) => { changeUserType(false) }}>
                      <BiBuilding  size="25" className="user-company-icon" />
                      <span className="mb-1">Company</span> {/* Decrease the bottom margin */}
                    </div>
                  </div>
                </div>



                  {userOptionSelected ?
                    <div> {/* User Inputs */}
                      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">

                        {/* Name input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Icon */}
                          <CiUser className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" value={name} onChange={(e) => updateName(e.target.value)} name="name" placeholder="Name" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Surname input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Icon */}
                          <CiUser className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" value={last} onChange={(e) => updateLast(e.target.value)} name="surname" placeholder="Surname" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Cell input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Icon */}
                          <CiMobile4 className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" value={cell} onChange={(e) => updateCell(e.target.value)}  name="cell" placeholder="Cell" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Email input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Icon */}
                          <CiMail  className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" value={email} onChange={(e) => updateEmail(e.target.value)} name="email" placeholder="Email" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Password input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Lock Icon */}
                          <CiLock className="absolute left-3 authentication-highlight-color " size="18" />
                          
                          {/* Input */}
                          <input type={showPassword ? "text" : "password"}  value={password} onChange={(e) => updatePassword(e.target.value)} name="password" placeholder="Password" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                          
                          {/* Eye Icon */}
                          <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => togglePasswordVisibility()}>
                            <IoEyeOutline className={showPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
                          </a>
                        </div>

                        {/* Confirm Password input with icon */}
                        <div className="relative flex items-center authentication-container-input">
                          {/* Icon */}
                          <CiLock className="absolute left-3 authentication-highlight-color " size="18" />
                          
                          {/* Input */}
                          <input type={showConfirmPassword ? "text" : "password"} value={cPassword} onChange={(e) => updateCPassword(e.target.value)} name="confirm-password" placeholder="Confirm Password" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                          
                          {/* Eye Icon */}
                          <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => toggleConfirmPasswordVisibility()}>
                            <IoEyeOutline className={showConfirmPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
                          </a>
                        </div>

                      </div>

                      <div className="mb-5 mt-5 ">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onChangeRecaptcha}
                        />
                      </div>

                      <div className="mb-2">
                          
                        <input
                          name="terms"
                          autoComplete="new-password"
                          className="form-control fwv-input-control mt-1 ml-2"
                          type="checkbox"
                          checked={terms}
                          onChange={(e) => {updateTerms(e.target.checked)}}                    
                        />

                        <label className="ml-2">
                          By registering you agree to the C1 FS{" "}
                          <a  rel="noreferrer"  href={termsPDF} target="_blank" className="primary-color"> Terms
                          </a>
                        </label>

                      </div>

                      <div className="mb-2">

                          <input
                            name="terms"
                            autoComplete="new-password"
                            className="form-control fwv-input-control mt-1 ml-2"
                            type="checkbox"
                            checked={popi}
                            onChange={(e) => {updatePopi(e.target.checked)}}
                                                  
                          />

                          <label className=" ml-2">
                            POPI{" "}
                            <a  rel="noreferrer"  href={popiPDF} target="_blank" className="primary-color">
                              Agreement
                            </a>
                          </label>

                      </div>

                    </div>

                  : 
                    <div> {/* Company Inputs */}
                      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">

                        {/* Name input with icon */}
                        <div className="relative flex items-center col-span-2 md:col-auto">
                          {/* Icon */}
                          <BiBuilding className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" name="name" value={name} onChange={(e) => updateName(e.target.value)} placeholder="Company Name" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Cell input with icon */}
                        <div className="relative flex items-center col-span-2 md:col-auto">
                          {/* Icon */}
                          <CiMobile4 className="absolute left-3 authentication-highlight-color " size="18" />
                          {/* Input */}
                          <input type="text" value={cell} onChange={(e) => updateCell(e.target.value)} name="cell" placeholder="Company Cell" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Email input with icon */}
                        <div className="relative flex items-center col-span-2 md:col-auto">
                          {/* Icon */}
                          <CiMail className="absolute left-3 authentication-highlight-color "  size="18" />
                          {/* Input */}
                          <input type="text" value={email} onChange={(e) => updateEmail(e.target.value)} name="email" placeholder="Company Email" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                        </div>

                        {/* Password input with icon */}
                        <div className="relative flex items-center col-span-2 md:col-auto">
                          {/* Icon */}
                          <CiLock className="absolute left-3 authentication-highlight-color " size="18" />
                          
                          {/* Input */}
                          <input type={showPassword ? "text" : "password"}  value={password} onChange={(e) => updatePassword(e.target.value)} name="password" placeholder="Password" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                          
                          {/* Eye Icon */}
                          <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => togglePasswordVisibility()}>
                            <IoEyeOutline className={showPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
                          </a>
                        </div>

                        {/* Confirm Password input with icon */}
                        <div className="relative flex items-center col-span-2 md:col-auto">
                          {/* Icon */}
                          <CiLock className="absolute left-3 authentication-highlight-color "  size="18" />
                          
                          {/* Input */}
                          <input type={showConfirmPassword ? "text" : "password"} value={cPassword} onChange={(e) => updateCPassword(e.target.value)} name="confirm-password" placeholder="Confirm Password" required className="w-full py-2 pl-10 authentication-bottom-border focus:border-blue-500 outline-none bg-transparent" />
                          
                          {/* Eye Icon */}
                          <a className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => toggleConfirmPasswordVisibility()}>
                            <IoEyeOutline className={showConfirmPassword ? "authentication-highlight-color" : "text-gray-400"} size="18" />
                          </a>
                        </div>
                      </div>

                      <div className="mb-5 mt-5 ">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onChangeRecaptcha}
                        />
                      </div>

                      <div className="mb-2">
                        
                        <input
                          name="terms"
                          autoComplete="new-password"
                          className="form-control fwv-input-control mt-1 ml-2"
                          type="checkbox"
                          checked={terms}
                          onChange={(e) => {updateTerms(e.target.checked)}}                    
                        />

                        <label className="ml-2">
                          By registering you agree to the C1 FS{" "}
                          <a  rel="noreferrer"  href={termsPDF} target="_blank" className="primary-color"> Terms
                          </a>
                        </label>

                      </div>

                      <div className="mb-2">

                        <input
                          name="terms"
                          autoComplete="new-password"
                          className="form-control fwv-input-control mt-1 ml-2"
                          type="checkbox"
                          checked={popi}
                          onChange={(e) => {updatePopi(e.target.checked)}}
                                                
                        />

                        <label className=" ml-2">
                          POPI{" "}
                          <a  rel="noreferrer"  href={popiPDF} target="_blank" className="primary-color">
                            Agreement
                          </a>
                        </label>

                      </div>

                    </div>            
                  }

                  {/* Registration Button */}
                  <div>

                    {showRegButton ?
                      <button type="button" className="w-full text-white py-2 font-semibold cursor-pointer authentication-button" onClick={(e) => {subLoginDetails(e)}}>
                        Register
                      </button>
                    : ''}

                  </div>

                  {/* Log In Option */}
                  <p className="text-sm text-center text-white">Already have an account? <a href="https://portal.c1fs.co.za/login" className="authentication-word-highlight hover:underline">Log In</a></p>
                
                </form>

              </section>

              {/* Right Sidebar */}
              <aside className="w-3/12">

              </aside>

            </main>

            {/* Footer */}
            <footer >
              <div className="w-full bg-transparent text-center text-gray-500 py-2">
                &copy; 2024 C1 FS. All Rights Reserved. |
                <a href="mailto:info@c1fs.co.za" className="hover:underline" >Support</a> | 
                <a href={Privacy} target="BLANK" className="hover:underline" >Privacy Policy</a> | 
                <a href="https://c1fs.co.za/" target="BLANK" className="hover:underline" >Our Website</a>
              </div>
            </footer>

          </div>


        : ''}

      </div>

    </React.Fragment>
    
  );

};

export default withRouter(Register);

Register.propTypes = {
    history: PropTypes.object,
  };

