import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

import X3Image from './Images/X3a.png'
import X5Image from './Images/X5b.png'
import loanImage from './Images/fund_loan.png'
import { UserContext } from "../../../../UserContext";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import PaymentModal from "./Modal";

function MyLTVLoanProducts() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #226A5E',
      };

    const{auth,user} = React.useContext(UserContext)
    const [userid, setUserid] = useState(null)
    const [displayModal, setDisplayModal] = useState(false)
    const [showPaymentPopupModal, setShowPaymentPopupModal] = useState(false)
    const [selectedLoanIDToPay, setSelectedLoanIDToPay] = useState(null)
    

      useEffect(() => {
        const fetchUserLoans = async (userInfo) => {
            await getUserLoans(userInfo)
        };

        if(auth && user)
        {
          setUserid(user.user.userid)
          fetchUserLoans(user.user);
        }
    
   
      }, [auth]);

    const [displayLoader, setDisplayLoader] = useState(false)
    const [cards, setCards] = useState([])

    // const cards = [
    //     { id: 1, title: 'Card 1', body: 'This is the body for card 1.', imageUrl: X3Image },
    //     { id: 2, title: 'Card 2', body: 'This is the body for card 2.', imageUrl: X5Image },
    //     // Add more cards as needed
    //   ];

    var currencyDisplay = {
        1: 'USDT',
        2: 'ZAR',
    }

      
      const getUserLoans = async(userInfo) => {
        const userObj = {
            userid: userInfo.userid
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-ltv-loans`,userObj).then(async (res)=>{
            console.log("Single Prodduct info: ", res.data)
            // {
            //     "id": 1,
            //     "userid": "C1A0000053",
            //     "loan_id": "",
            //     "term_months": 0,
            //     "multiplier": 3,
            //     "currency": "1",
            //     "user_amount": 100,
            //     "loan_amount": 210,
            //     "payout_amount": 300,
            //     "pool_balance_amount": 90,
            //     "status": "0",
            //     "createdAt": "2024-02-28T12:57:34.000Z",
            //     "updatedAt": "2024-02-28T12:57:34.000Z"
            // }
            if(res.data.status === 200)
            {
                setCards(res.data.loans)
            }
        })
      }

      const percentage = 0;

      const makeLoanPayment = async(loanInfo) => {
        await sendOTP();
        console.log("Loan info is: ", loanInfo)

        setSelectedLoanIDToPay(loanInfo)
        setShowPaymentPopupModal(true)
      }

      const closeDepositModal = () => {
        setSelectedLoanIDToPay(null)
            setShowPaymentPopupModal(false)
      }

      const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
 
            if(res.data.status == 200)
            {
                console.log("We have sent the otp")
                toast.success('OTP Pin Successfully Sent!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

            }else{
            
                toast.error('Unable To send OTP!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
            
        })

    }

    const calculateLoanPaidPercentage = (loanAmount, remainingAmount) => {
        if (!loanAmount || loanAmount === 0) return 0; // Avoid division by zero
        const paidAmount = loanAmount - remainingAmount;
        return (paidAmount / loanAmount) * 100;
      };


  return (
    <>
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                />
    {showPaymentPopupModal ?
        <PaymentModal userid={userid} loanInfo={selectedLoanIDToPay} closeDepositModal={closeDepositModal} showPaymentPopupModal={showPaymentPopupModal} />
          : ''}
        <div className="container mx-auto px-2 pt-8 pb-8">
            <div className="mb-5">
            <h3 className="mb-6 mt-6 text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">
                LTV Loans
            </h3>
            </div>
            {displayLoader ?
            <p>Loading...</p>
            :
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap -m-4">
                    {cards.map((card) => (
                    <div key={card.id} className="p-4 md:w-1/3">
                        <div className="h-full border-2 hilighted-card-box  overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src={loanImage} alt="image" />
                            <div className="p-6">
                                <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">{card.loan_id}</h2>
                                <span className={`px-3 py-1 text-xs font-semibold text-white rounded-full ${
                                    card.status === '2' ? 'bg-red-500' :
                                    card.status === '1' ? 'bg-green-500' : 'c1-pending-background'
                                }`}>
                                    {card.status === '2' ? 'Inactive' :
                                    card.status === '1' ? 'Active' : 'Pending'}
                                </span>

                                
                                            <div>
                                    <div className="p-1">
                                        <span >Collateral Amount: </span><span className="primary-color uppercase">{card.collateral_amount + card.collat_coin}</span>
                                    </div>
                                    <div className="p-1">
                                        <span >Loan Amount: </span><span className="primary-color uppercase">{card.loan_amount + card.loan_coin}</span>
                                    </div>
                                    <div className="p-1">
                                        <span >LTV Percentage: </span><span className="primary-color uppercase">{card.collateral_percentage + '%'}</span>
                                    </div> 
                                    <div className="p-1">
                                        <span >Liquidation Amount: </span><span className="primary-color uppercase">{parseFloat(card.liquidation_amount).toFixed(2) + card.loan_coin}</span>
                                    </div>
                                    <div className="p-1">
                                        <span >Make use of C1 FS products to pay back loan: </span><span className="primary-color">{card.company_proceeds === 'true' ? "Yes" : "No"}</span>
                                    </div>
                                    <div className="p-1">
                                        <span >Loan Amount Remaining: </span><span className="primary-color uppercase">{card.amount_remaining + card.loan_coin}</span>
                                    </div>
                                    <div className="p-1">
                                        <span >Months Remaining: </span><span className="primary-color">{card.period}</span>
                                    </div>  
                                    <div className="p-1">
                                        <span >Date: </span><span className="primary-color">{card.createdAt}</span>
                                    </div>  
                                    <div className="p-1 mt-2" style={{width: "60%"}}>
                                    <CircularProgressbar
                                        value={
                                            ((card.loan_amount - card.amount_remaining) / card.loan_amount) * 100
                                        }
                                        text={`${
                                            (((card.loan_amount - card.amount_remaining) / card.loan_amount) * 100).toFixed(2)
                                        }% Paid`}
                                        styles={buildStyles({
                                            textSize: "11px",
                                            textColor: "white",
                                            pathColor: "#19544a",
                                            trailColor: "grey"
                                        })}
                                    />
                                    </div>  

                                    {card.status === '1' ?
                                    <div className="mt-3">
                                        <button onClick={() => {makeLoanPayment(card)}} className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                            Make Payment
                                        </button>
                                    </div> 
                                    : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            }
        </div>
        </>
  )
}

export default MyLTVLoanProducts