import React from 'react'

import {decode as base64_decode, encode as base64_encode} from 'base-64';

function SingleNotification(props) {
  return (
    <div>
      <a href={"./notifications?n_id=" + base64_encode(props.notification.id)} >
        <div className="grid grid-cols-6 mt-2 border-t border-white p-2">
          <div className='m-auto 2 border-r-1'>
            <p>{props.notification.date}</p>
          </div>

          <div className={"col-span-5 " + (props.notification.status === "new" ? "border-r-4 border-red-600" : "")} >
            <h1 >{props.notification.title}</h1>
            <h4 className='text-sm'>{props.notification.description}</h4>
          </div>
        </div>
      </a>
    </div>
  )
}

export default SingleNotification