import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronDown, faCog, faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons'

function Wallet() {

  const{auth,user} = React.useContext(UserContext)

  const [userBalance, setUserBalance] = useState({zar: 0, btc: 0, usdt: 0})
  const [userUSDT, setUSDT] = useState(0)
  const [userZAR, setZAR] = useState(0)
  const [userBTC, setBTC] = useState(0)
  const [displayCurrencies, setDisplayCurrencies] = useState(false)
  const [currencyDisplay, setCurrencyDisplay] = useState('ZAR')
  const [showDropdown, setShowDropdown] = useState(false); 
  const [userBalances, setUserBalances] = useState([]); 

  React.useEffect(()=>{
    
    if(auth && user)
    {
      getUserBalance(user.user.userid)
     
      if(user.user.fica_status === 'A' && user.user.u_status === 'A')
      {
        
        getUserDetails(user.user.userid)
      
        // var mainString = user.user.
        // mainString.includes(searchString)
      }

    }

  },[auth])

  const getUserDetails = async (userid) => {
    var userObj = {
        userid: userid
      }
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
          var mainString = res.data.info.country
          if(mainString && mainString.includes("Namibia"))
          {
            setCurrencyDisplay("NAD")
          }
        }
      });
      setDisplayCurrencies(true)
    }

  const getUserBalance = async(userid) =>{
    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balances`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
        if(res.data.currencies.length > 3 )
          setUserBalances(res.data.currencies)
        else{
          await getUserBalance2(userid)
          setUserBalances(res.data.currencies)
        }

      }
    })

    return true;
  }

  const getUserBalance2 = async(userid) =>{
    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
     
        setZAR(truncateDecimals(res.data.currencyInfo[1].balance, 2))
        
        setUSDT(truncateDecimals(res.data.currencyInfo[0].balance, 2))
        setBTC(truncateDecimals(res.data.currencyInfo[2].balance, 6))
      }
    })

    return true;
  }

  function truncateDecimals(number, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.floor(number * multiplier) / multiplier;
  }

  return (
    <div>
    {displayCurrencies && userBalances.length > 0 ?
    userBalances.length > 3 ?
      <div className="items-center justify-end" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
        <div className="relative">
          <button className="p-2 text-white-600 text-center border border-transparent rounded c1-primary-menu-hover transition-colors duration-300" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
            <p className="primary-color rounded transition-colors duration-300">
              {userBalances[0].name} {truncateDecimals(userBalances[0].balance, userBalances[0].currency_decimals)}
              <FontAwesomeIcon className="ml-2" icon={faCircleChevronDown} size="xl" />
            </p>
          </button>
          {showDropdown && (
            <div className="absolute right-0 w-48 bg-black border primary-border rounded-md shadow-lg" style={{ marginTop: "-10px" }} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
              {userBalances.slice(1).map((currency, index) => (
                <p key={index} className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
                  {currency.name} {truncateDecimals(currency.balance, currency.currency_decimals)}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      :

        <div className=" md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0 " id="navbar-collapse">
          <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
              {currencyDisplay} {userZAR}
          </p>
          <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
              USDT {userUSDT}
          </p>
          <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
              BTC {userBTC}
          </p>
        </div> 
    :
    <div class="loader-c1fs"></div>}
  </div>

  )
}

export default Wallet