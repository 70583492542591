import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../../../../../UserContext'
import axios from 'axios'

function SubmitForm({changeStepProcess, closeProductModal}) {

    const{auth,user} = React.useContext(UserContext);

    const[userid, setUserid] =  React.useState('');

    const[wallet_address, setWalletAddress] =  React.useState('');
    const[txid, setTXID] =  React.useState('');
    const[currency, setCurrency] =  React.useState('');
    const[amount, setAmount] =  React.useState(0);

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
            setUserid(user.user.userid)
          }
        }
      }, [auth])

      const submitDepositRequest = async () => {

        if(userid === '' || wallet_address === '' || amount === '' || txid === '' || currency === '')
            return;
            
        var userObjDeposit = {
          userid: userid,
          wallet_address: wallet_address,
          txid: txid,
          from_amount: amount,
          currency: currency
        }
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/create-external-product-deposit",userObjDeposit).then( async(res)=>{
      
     
                  
          if(res.data.status === 200)
          {
            changeStepProcess(6)
          }
      });
      }

  return (
    <div>
        <h2 className="text-center">Subbmit Payment Request</h2><br />

        <p>Please submit the following info in order for us to fund your AIT Wallet:</p><br />

        <div className="w-full ">
            <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                <label className="block text-light text-sm font-bold mb-2" for="username">
                    Wallet Address
                </label>
                <input style={{background: "transparent"}} className="shadow text-light appearance-none border rounded w-full py-2 px-3 text-light " id="username" type="text" placeholder="Wallet Address" value={wallet_address} onChange={(e) => {setWalletAddress(e.target.value)}} />
                </div>

                <div className="mb-4">
                <label className="block text-light text-sm font-bold mb-2" for="username">
                    TXID
                </label>
                <input value={txid} onChange={(e) => {setTXID(e.target.value)}} style={{background: "transparent"}} className="shadow text-light appearance-none border rounded w-full py-2 px-3 text-light " id="username" type="text" placeholder="TXID" />
                </div>

                <div className="mb-4">
                <label className="block text-light text-sm font-bold mb-2" for="username">
                    Currency
                </label>
                <select value={currency} onChange={(e) => {setCurrency(e.target.value)}} style={{background: "transparent"}} className="shadow text-light appearance-none border rounded w-full py-2 px-3 text-light ">
                    <option value="">
                        Select
                    </option>
                    <option value="usdt">
                        USDT
                    </option>
                    <option value="btc">
                        BTC
                    </option>
                </select>
                </div>

                <div className="mb-4">
                <label className="block text-light text-sm font-bold mb-2" for="username">
                    Amount
                </label>
                <input value={amount} onChange={(e) => {setAmount(e.target.value)}} style={{background: "transparent"}} className="shadow text-light appearance-none border rounded w-full py-2 px-3 text-light " id="username" type="number" min="0" placeholder="Amount" />
                </div>

            </form>
            </div>

        <br />

        {/* Navigation Buttons */}
        <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #226A5E",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {submitDepositRequest()}}>Submit</Button>


        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #226A5E",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(4)}}>Back</Button>
        </div>
            
    </div>
  )
}

SubmitForm.propTypes = {
    closeProductModal: PropTypes.func,
    changeStepProcess:PropTypes.func,
}

export default SubmitForm