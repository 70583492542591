import React from 'react'

function LoadingText({title, description}) {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <box-icon className="text-center" name='loader-circle' animation='spin' flip='vertical' size="lg" color="white" ></box-icon>
      <h1 className="text-white text-center">{title}</h1>
      <h4 className="text-white text-center">{description}</h4>
    </div>
  )
}

export default LoadingText