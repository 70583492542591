import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

function LoanHistory({userid}) {

    const [loanInfo, setLoanInfo] = useState(null)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await getUserLoanOptions();
        };
    
        fetchData();
    }, [userid]); 

    const getUserLoanOptions = async() => {

        const userObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/get-user-loan-ltv-info",userObj).then(async (res)=>{
            console.log("User Loan Info: ", res.data)
            if(res.data.status === 200)
            {
                setLoanInfo(res.data.data)
            }
        });
    }
  return (
    <div id="laon-info-text" className="container-md px-4 pl-6">
        <div className="w-full">
        
            <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box">
                <div className="mb-4">
                    <h1 className="title text-white text-center mb-2">LTV Loan</h1>

                    {!loanInfo || loanInfo.activeLoans == 0 && loanInfo.pendingLoans == 0 ?
                   <div className="max-w-4xl mx-auto p-5 font-sans text-gray-300">
                   <h2 className="text-2xl font-semibold text-gray-400 mb-4">Unlock the Potential of LTV Loans: Your Path to Financial Flexibility with Crypto!</h2>
                   <p className="mb-4">We're thrilled to introduce our LTV Loan, a groundbreaking solution designed to harness the value of your cryptocurrency assets! This unique offering enables you to leverage your Bitcoin (BTC) and Ethereum (ETH) as collateral, securing a loan in USDT that provides immediate liquidity while retaining your crypto investments.</p>
                   
                   <h3 className="text-xl font-semibold text-gray-400 mb-3">Simplifying LTV Loans for Everyone</h3>
                   <ul className="list-disc pl-5 mb-4">
                       <li><strong>Use Your Crypto as Collateral:</strong> Easily use your BTC and ETH to secure a loan, giving you access to USDT without having to sell your valuable crypto assets.</li>
                       <li><strong>Flexible Repayment Terms:</strong> Opt for a 60-month term, and we’ll assist in paying off your loan, demonstrating our commitment to your financial well-being.</li>
                       <li><strong>Get USDT, Maintain Your Crypto:</strong> Receive USDT as the loan currency, offering you the liquidity you need while your BTC or ETH potentially grows in value.</li>
                       <li><strong>Long-Term Support:</strong> Choosing a 60-month repayment term allows us to help in managing and paying off your loan, ensuring you’re supported every step of the way.</li>
                   </ul>
                   
                   <h3 className="text-xl font-semibold text-gray-400 mb-3">Effortless Access to Funds with Peace of Mind</h3>
                   <p className="mb-4">Our LTV Loan service is crafted to make your journey to financial freedom as smooth as possible. It’s not just about providing you with immediate funds; it’s about offering a strategic avenue to leverage your digital assets for financial flexibility without giving up the future growth of your crypto holdings.</p>
                   
                   <h3 className="text-xl font-semibold text-gray-400 mb-3">Ready to Leverage Your Crypto for Immediate Liquidity?</h3>
                   <p>Join our innovative platform today and discover how easy it is to secure a loan with your cryptocurrency. With our LTV Loan, your path to unlocking liquidity while holding onto your assets is clearer than ever. Start now and embrace the financial flexibility that awaits.</p>
               </div>
                    : ''}
                    
                    <div>
                        <div className="p-2">
                            <span >Active Loans: </span><span className="primary-color">{loanInfo ? loanInfo.activeLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Pending Loans: </span><span className="primary-color">{loanInfo ? loanInfo.pendingLoans : "N/A"}</span>
                        </div>
                        <div className="p-2">
                            <span >Loans Completed More Than 70% of TimeLine: </span><span className="primary-color">{loanInfo ? loanInfo.almostCompletedLoans : "N/A"}</span>
                        </div>  
                        

                        <div className="flex items-center justify-between">
                            <a href="./my-ltv-loan-products" className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                View My Loan Products
                            </a>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
  )
}

export default LoanHistory