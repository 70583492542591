import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFolderBlank, faPlus, faHistory, faUser } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { UserContext } from "../../UserContext";

import axios from 'axios'

const getbottomPossitionTotal = async(numberofItems) => {
  var total = numberofItems * 20;
  return total;
}


function MobileFooterMenu({userInfo=null}) {

  const{auth,user} = React.useContext(UserContext)
  const[userCompany, setUserCompany] =  React.useState(null);

  React.useEffect( () => {
  
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        setUserCompany(user.user.company)
        assignProductsToHeader(user)

      }
    }
  }, [auth])


  const [isPopupProductsVisible, setPopupProductsVisible] = useState(false);
  const [isPopupTransactionVisible, setPopupTransactionVisible] = useState(false);
  const [isPopupHistoryVisible, setPopupHistoryVisible] = useState(false);
  const [isPopupProfileVisible, setPopupProfileVisible] = useState(false);

  const[products, setProducts] =  React.useState([{ title: "User Information Required", link: "#" }]);
  const[bottomPositionproduct, setBottomPositionproduct] =  React.useState(40);

  let transactionCategories = [];
  let transactionNumItems = 0;
  let bottomPositionTransactions = 40;

  let productCategories = [];
  let productNumItems = 0;
  // let bottomPositionproduct = 40;

  if(userInfo && userInfo.u_status === 'A' && userInfo.fica_status === 'A')
  {

    // Transactions
    transactionCategories = [
      {
        title: 'Deposit',
        link: '/deposit',
      },
      {
        title: 'Withdraw',
        link: '/withdraw'
      },
      { 
        title: 'Exchange', 
        link: '/exchange'
      },
      { 
        title: 'History', 
        link: '/transactions'
      },
      
    ];

    // Calculate the number of menu items
    transactionNumItems = transactionCategories.length;

    // Calculate the bottom position based on the number of menu items
    bottomPositionTransactions = getbottomPossitionTotal(transactionNumItems); // Adjust the 40 and 60 values as needed
    // End Transactions

    // Products
    transactionCategories = [
      {
        title: 'Deposit',
        link: '/deposit',
      },
      {
        title: 'Withdraw',
        link: '/withdraw'
      },
      { 
        title: 'Exchange', 
        link: '/exchange'
      },
      { 
        title: 'History', 
        link: '/transactions'
      },
      
    ];
    // End Products

  }else{
    // Transactions
    transactionCategories = [
      { title: "User Information Required", link: "#" },
    ];

    // Calculate the number of menu items
    transactionNumItems = transactionCategories.length;

    // Calculate the bottom position based on the number of menu items
    bottomPositionTransactions = getbottomPossitionTotal(transactionNumItems); // Adjust the 40 and 60 values as needed
    // End Transactions

  }

  var assignProductsToHeader = async(user) => {

    if(user.user.u_status !== 'A' || user.user.fica_status !== 'A') return;

    var userObj = {
      id: user.user.id
    }
    
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}get-user-saved-products`,userObj).then((res)=>{

      var newProductArray = []
      var newproducts = res.data.data
      var newObject = {}
  
      for (let productid of newproducts) {
  
          
        if(productid === '15')
        {
          newObject = {
            title: 'Transaction Accumulation Fund',
            link: '/accumulation-fund'
          }
          
        }else if(productid === '22' || productid === '23' || productid === '24' || productid === '25')
        {
          newObject = {
            title: 'Capital Interest Vault',
            link: '/products/capital-interest-vault'
          }
        
        }else if(productid === '26' || productid === '27' || productid === '28')
        {
          newObject = {
            title: 'Savings Bundle',
            link: '/products/savings-bundle'
          }
          
        }else if(productid === '29' || productid === '30' || productid === '31' || productid === '32')
        {
          newObject = {
            title: 'Tokenized Index',
            link: '/products/tokenized-index'
          }
         
        }else if(productid === '33' || productid === '34' || productid === '35')
        {
          newObject = {
            title: 'Monetary Stability Fund',
            link: '/products/monetary-stability-fund'
          }
           
        }else if(productid === '36' || productid === '37' || productid === '38' || productid === '39')
        {
          newObject = {
            title: 'Accumalation Vault',
            link: '/products/accumalation-vault'
          }
         
        }else if(productid === '40')
        {
          newObject = {
            title: 'Fund Builder',
            link: '/products/fund-builder'
          }
         
        }else if(productid === '41')
        {
          newObject = {
            title: 'Solar Flare',
            link: '/products/solar-flare'
          }
         
        }else if(productid === '42')
        {
          newObject = {
            title: 'Stella Nova',
            link: '/products/stella-nova'
          }
         
        }else if(productid === '43')
        {
          newObject = {
            title: 'Galaxy Pulse',
            link: '/products/galaxy-pulse'
          }
         
        }else if(productid === '44')
        {
          newObject = {
            title: 'Supernova Spark',
            link: '/products/supernova-spark'
          }
         
        }else if(productid === '45' || productid === '46' || productid === '47')
        {
          newObject = {
            title: 'Wealth Creator',
            link: '/products/wealth-creator'
          }
           
        }else if(productid === '48')
        {
          newObject = {
            title: 'Stable Fund Loan',
            link: '/product/stable-fund-loan',
          }
         
        }else if(productid === '49')
        {
          newObject = {
            title: 'Wealth Loan2',
            link: '/product/loan-calculator2',
          }
         
        }else if(productid === '50')
        {
          newObject = {
            title: 'LTV Loan',
            link: '/product/ltv-loan',
          }
         
        }
   
   
   
        const isObjectIncluded = newProductArray.some((obj) => {
          
          if(obj.title === newObject.title) return true
          else return false
         
        });
 
        if (!isObjectIncluded) {
          newProductArray.push(newObject);
        }
      }
  
     
  
      setProducts(newProductArray)
      // Products
      // Calculate the number of menu items
      productNumItems = newProductArray.length;

      // Calculate the bottom position based on the number of menu items
      setBottomPositionproduct(getbottomPossitionTotal(productNumItems)); // Adjust the 40 and 60 values as needed

    })
  
  }


  // Set the bottom property dynamically
  const popupMenuStyle = {
    bottom: `${bottomPositionTransactions}px`,
  };

  // Set the bottom property dynamically
  const popupMenuStyleProducts = {
    bottom: `${bottomPositionproduct}px`,
  };

  // const productCategories = [
  //     { title: "Product 1", link: "/subcategory1" },
  //     { title: "Product 2", link: "/subcategory2" },
  //     // Add more productCategories as needed
  //   ];

  // const transactionCategories = [
  //   { title: "Transaction 1", link: "/subcategory1" },
  //   { title: "Transaction 2", link: "/subcategory2" },
  //   // Add more transactionCategories as needed
  // ];

  const historyCategories = [
    { title: "Archive 1", link: "/subcategory1" },
    { title: "Transaction 2", link: "/subcategory2" },
    // Add more historyCategories as needed
  ];

  const togglePopupHome = () => {
    // Take me to the dashboard page
    window.location.href="/dashboard"
  };

    const togglePopupMenu = (menuName) => {
      if(menuName === 'products')
      {
        setPopupProductsVisible(!isPopupProductsVisible);
        setPopupTransactionVisible(false);
        setPopupHistoryVisible(false);
        setPopupProfileVisible(false);
      }

      if(menuName === 'transactions')
      {
        setPopupProductsVisible(false);
        setPopupTransactionVisible(!isPopupTransactionVisible);
        setPopupHistoryVisible(false);
        setPopupProfileVisible(false);
      }

      if(menuName === 'history')
      {
        window.location.href="/reports"
      }

      if(menuName === 'profile')
      {
        setPopupProductsVisible(false);
        setPopupTransactionVisible(false);
        setPopupHistoryVisible(false);
        setPopupProfileVisible(!isPopupProfileVisible);
      }
    }


    const logout = async ()=>{

      var options =
      {
        isAdmin:true,
        userid: userInfo.userid
      }
      await axios.post(process.env.REACT_APP_BASE + "api/logout",options,{withCredentials:true}).then((res)=>{
        // window.location('/login-register');
       
        window.location.href="/login"
        
      })
    }

    const profileCategories = [
      { title: "Settings", link: "/settings" },
      { title: "Log out", onClick: logout },
      { title: "My Products", link: "/my-packages" },
      // Add more historyCategories as needed
    ];


  return (
    <div className="hide-on-mobile">

      {/* This is for products */}
      {isPopupProductsVisible && (
        <div className="popup-menu" style={popupMenuStyleProducts}>
          {products.map((subcategory, index) => (
              <a key={index} href={subcategory.link} className="popup-menu-item">
                {subcategory.title}
              </a>
          ))}
        </div>
      )}

      {/* This is for transactions */}
      {isPopupTransactionVisible && (
        <div className="popup-menu" style={popupMenuStyle}>
          {transactionCategories.map((subcategory, index) => (
              <a key={index} href={subcategory.link} className="popup-menu-item">
                {subcategory.title}
              </a>
          ))}
        </div>
      )}

      {/* This is for history */}
      {isPopupHistoryVisible && (
        <div className="popup-menu">
          {historyCategories.map((subcategory, index) => (
              <a key={index} href={subcategory.link} className="popup-menu-item">
                {subcategory.title}
              </a>
          ))}
        </div>
      )}

      {/* This is for profile */}
      {isPopupProfileVisible && (
        <div className="popup-menu">
          {profileCategories.map((subcategory, index) => (
              <a key={index} href={subcategory.link} onClick={subcategory.onClick} className="popup-menu-item">
                {subcategory.title}
              </a>
          ))}
        </div>
      )}


      <div className="mobile-footer-menu">

        <div className="mobile-footer-menu-item" onClick={togglePopupHome}>
          <div>
            <FontAwesomeIcon icon={faHome} className="mobile-footer-menu-icon" />
          </div>
          <span className="mobile-footer-menu-text">Home</span>
        </div>

        <div className="mobile-footer-menu-item" onClick={() => togglePopupMenu('products')}>
          <div>
            <FontAwesomeIcon icon={faFolderBlank} className="mobile-footer-menu-icon" />
          </div>
          <span className="mobile-footer-menu-text">Products</span>
        </div>

        <div className="mobile-footer-menu-item special deposit-container" onClick={() => togglePopupMenu('transactions')}>
          <div className="half-circle">
              <FontAwesomeIcon icon={faPlus} className="mobile-footer-menu-icon plus-icon" style={{ zIndex: 2 }} />
          </div>
          <span className="mobile-footer-menu-text">Transactions</span>
        </div>

        <div className="mobile-footer-menu-item" onClick={() => togglePopupMenu('history')}>
          <div>
            <FontAwesomeIcon icon={faHistory} className="mobile-footer-menu-icon" />
          </div>
          <span className="mobile-footer-menu-text">Documents</span>
        </div>

        <div className="mobile-footer-menu-item" onClick={() => togglePopupMenu('profile')}>
          <div>
            <FontAwesomeIcon icon={faUser} className="mobile-footer-menu-icon" />
          </div>
          <span className="mobile-footer-menu-text">Profile</span>
        </div>


      </div>

      <Footer newClass="mb-4 mobile-footer"  />
    </div>
  );
}

export default MobileFooterMenu;

