import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function ExchangeFrom({changeStepProcess, changeSelectedFromCurrency, selectedCurrency, countryCurrency}) {
  return (
    <div>
        <h2 className="text-center">Select From Currency</h2><br />

        <a onClick={() => changeSelectedFromCurrency('usdt')} className={`${selectedCurrency === 'usdt' ? 'menu-item-selected' : ''} text-white font-bold py-2 px-4 rounded-full mr-2`} value={'usdt'}>USDT</a>
        <a onClick={() => changeSelectedFromCurrency('btc')}  className={`${selectedCurrency === 'btc' ?  'menu-item-selected' : ''} text-white font-bold py-2 px-4 rounded-full mr-2`} value={'btc'}>BTC</a>
        <a onClick={() => changeSelectedFromCurrency('zar')}  className={`${selectedCurrency === 'zar' ?  'menu-item-selected' : ''} text-white font-bold py-2 px-4 rounded-full mr-2`} value={'zar'}>{countryCurrency}</a>

        {/* Navigation Buttons */}
        <div className='mt-3'>
        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #226A5E",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(8)}}>Next</Button>

        <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #226A5E",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={() => {changeStepProcess(3)}}>Back</Button>
        </div>
            
    </div>
  )
}

ExchangeFrom.propTypes = {
    changeSelectedFromCurrency: PropTypes.func,
    changeStepProcess:PropTypes.func,
    selectedCurrency:PropTypes.any,
    countryCurrency:PropTypes.any,
}

export default ExchangeFrom