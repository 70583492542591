import React from 'react'
import { useParams } from 'react-router';

import FundForm from '../../components/Products/Forms/FundForm'
import { UserContext } from '../../UserContext'

function ProductsPage() {

    const{auth,user} = React.useContext(UserContext)
    const [productData, setProductData] = React.useState([]);
    const [productDataTitle, setProductDataTitle] = React.useState('');
    const [redirectToDash, setRedirectToDash] = React.useState(true);

    let { product } = useParams()

    const products = [
        {
            mainTitle: 'Capital Interest Vault',
            slug: "capital-interest-vault",
            title: "Capital Interest Vault",
         
            duration: {6: 22, 12 : 23, 36: 24, 60: 25},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: {1: 5000, 3: 0.14},
            max: null,
            reContribute: {1: 1000, 3: 0.027},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>Cryptocurrency deposits continue to generate high returns due to the demand for borrowing assets that are not easily accessible through traditional means. However, as the crypto market evolves and capital becomes more readily available, the yields will gradually decrease. 
            Despite this, we believe that there will be opportunities to generate yield to offset the increasing accessibility of capital in the crypto space.</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months, but 12 months, 3- and 5-year options also available. Funds are locked for the 6-month period (or alternative period chosen) and proceeds are paid back into the fund. 
            At the end of the 6-month (or alternative period chosen) the funds and proceeds can be withdrawn or re-invested.</p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out monthly on the 5th day of each month. 
            At the end of the 6-month period (or alternative period chosen) the funds and proceeds can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$5,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Savings Bundle',
            slug: 'savings-bundle',
            title: "Savings Bundle",
            duration: {12 : 26, 36: 27, 60: 28},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: {1: 5000, 3: 0.14},
            max: null,
            reContribute: {1: 1000, 3: 0.027},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>Our investment strategy is typically focused on smart capital growth and may involve a diversified portfolio of blockchain investments. 
            Overall, our funds investment strategy is designed to help investors build wealth over the long term by investing in a diversified portfolio of assets and reinvesting proceeds back into the fund. 
            This can help to maximize returns while managing risk and volatility over time.</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3 and 5-year options also available. Funds are locked for the 12-month period and proceeds are paid back into the fund. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out annually. At the end of the 12-month period the funds and proceeds can be withdrawn or re-invested.</p><br />

            <p>Minimum Contribution: </p>
            <p>$5,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Tokenized Index',
            slug: 'tokenized-index',
            title: "Tokenized Index",
            duration: {6: 29, 12 : 30, 36: 31, 60: 32},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: {1: 10000, 3: 0.27},
            max: null,
            reContribute: {1: 5000, 3: 0.14},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The Tokenized Index Fund is a proposed investment fund that aims to provide stability in the face of inflation using cryptocurrencies as its underlying asset. 
            The fund will use a basket of the top 10 cryptocurrencies, carefully selected for their potential to provide a hedge against inflation and maintain value over time.
            Overall, the Tokenized Index Fund aims to provide investors with a stable and reliable investment option that can help them preserve the value of their wealth and hedge against inflation. By investing in a carefully curated basket of cryptocurrencies, the fund offers an innovative solution that can help investors achieve their financial goals while minimizing risk.
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months, but 12 months, 3- and 5-year options also available. Funds are locked for the 6-month period (or alternative period chosen) and proceeds are paid back into the fund. 
            At the end of the 6-month (or alternative period chosen) the funds and proceeds can be withdrawn or re-invested.</p><br />
        
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out Quarterly. At the end of the 6-month period (or alternative period chosen) the funds and proceeds can be withdrawn or re-invested. </p><br />

            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Monetary Stability Fund',
            slug: 'monetary-stability-fund',
            title: "Monetary Stability Fund",
            duration: {12 : 33, 36: 34, 60: 35},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: {1: 20000, 3: 0.54},
            max: null,
            reContribute: {1: 10000, 3: 0.27},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The fund's strategy is to invest in cryptocurrencies that are expected to appreciate in value at a rate that exceeds the rate of inflation. 
            By doing so, the fund seeks to outperform traditional investment options and provide a viable alternative for investors seeking protection against inflation.

            The Monetary Stability Fund aims to provide investors with a stable and reliable investment option that can help them preserve the value of their wealth and hedge against inflation. By investing in a carefully curated basket of cryptocurrencies, the fund offers an innovative solution that can help investors achieve their financial goals while minimizing risk. 
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3- and 5-year options also available. Funds are locked for the 12-month period and proceeds are paid back into the fund. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out Quarterly. At the end of the 12-month period the funds and proceeds can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$20,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'C1 FS Accumalation Vault',
            slug: 'accumalation-vault',
            title: "C1 FS Accumalation Vault",
            duration: {12 : 37, 36: 38, 60: 39},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 3000},
            max: null,
            reContribute: {1: 3000},
            currencies: [1],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The Accumulation Fund is a proposed investment fund that aims to provide stability in the face of
            inflation using cryptocurrencies as its underlying asset. The fund will use a basket of the top 10
            cryptocurrencies, carefully selected for their potential to provide a hedge against inflation and
            maintain value over time. 

            The fund's strategy is to invest in cryptocurrencies that are expected to appreciate in value at a
rate that exceeds the rate of inflation. By doing so, the fund seeks to outperform traditional
investment options and provide a viable alternative for investors seeking protection against
inflation. 
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3- and 5-year options also available. Proceeds are paid back into the fund. 
            At the end of your investment term, your funds and any profits generated will become available for withdrawal or reinvestment. This gives you the opportunity to decide whether to cash out or continue investing with us once your investment period comes to an end.</p><br />
        
            <p>Minimum Contribution: </p>
            <p>$3,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Fund Builder',
            slug: 'fund-builder',
            title: "Fund Builder",
            duration: {6 : 40},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 100, 3: 0.0027},
            max: {1: 2000, 3: 0.054},
            reContribute: {1: 100, 3: 0.0027},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The Fund Builder is a mutual fund designed to
            follow certain pre-set rules so that the fund can
            track a specified basket of underlying investments,
            in the case of this Fund Builder the underlying
            instrument will be the USD (US Dollar) and BTC
            (Bitcoin).</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months. Funds are locked for the 6-month period and proceeds are paid back into the wallet. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out monthly. At the end of the 6-month period the funds can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$100</p><br />

            <p>Maximum Contribution: </p>
            <p>$2000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Wealth Creator',
            slug: 'wealth-creator',
            title: "Wealth Creator",
            duration: {6 : 45, 12: 46, 18: 47},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 27000, 3: 0.73},
            max: null,
            reContribute: {1: 10000, 3: 0.24},
            currencies: [1,3],
             productid: '',
            description: `<p>Product Objective:</p>
            <p>Experience the rare combination of high returns and low risk.
            Wealth Creator is an exceptional
            investment choice for those seeking stability and growth.</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months. Funds are locked for the 6-month period and proceeds are paid back into the wallet. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out monthly. At the end of the 6-month period the funds can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$27000 or 0.73 BTC</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Solar Flare',
            slug: 'solar-flare',
            title: "Solar Flare",
            duration: {12 : 41},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 2500, 3: 0.068},
            max: null,
            reContribute: {1: 2500, 3: 0.068},
            currencies: [1,3],
             productid: '',
            description: `<h5>Product Objective:</h5>
            <p>Empower your financial journey with profits gained through the cutting-edge MrOneDollar Solar Flare Package, managed by top traders.
            4% Monthly Return: Enjoy a consistent 4% return as interest on your investment, sent directly to your wallet, fostering further growth.</p><br />

            <h5>Benefits:</h5>
            <p>Optimized Profitability - Leverage the expertise of top traders to optimize your profits with minimal effort.</p>
            
            <p>Consistent Returns - Benefit from a steady and reliable interest income on your investment every month.</p>
            <br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months. Funds are locked for the 12-month period and proceeds are paid back into your wallet.</p><br />
        
            <p>Minimum Contribution: </p>
            <p>$2,500</p><br />`
        },
        {
            mainTitle: 'Stella Nova',
            slug: 'stella-nova',
            title: "Stella Nova",
            duration: {12 : 42},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 5000, 3: 0.14},
            max: null,
            reContribute: {1: 5000, 3: 0.14},
            currencies: [1,3],
             productid: '',
             description: `<h5>Product Objective:</h5>
            <p>All features from the SolarFlare package.</p>
            <p>New Business Registration: Kickstart your venture with our streamlined business registration process.</p>
            <p>Dedicated Business Bank Account: Manage your finances efficiently with a bank account dedicated to your new business.</p>
            <p>Promotors Business Compliance Setup: Ensure your business's legality and compliance, approved by FSCA.</p>
            <p>Website Creation: Establish your online presence with a professionally designed website.</p>
            <p>Accounting & Tax Services: Stay ahead with up to 75 bank transactions per month, monthly tax returns, and online filing software.</p><br />

            <h5>Benefits:</h5>
            <p>Comprehensive Business Solutions - From registration to compliance, receive end-to-end solutions for your new business.</p>
            
            <p>Strategic Financial Management - Expert accounting and tax services to strategize your financial growth effectively.</p>
            <br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months. Funds are locked for the 12-month period and proceeds are paid back into your wallet.</p><br />
        
            <p>Minimum Contribution: </p>
            <p>$5000</p><br />`
        },
        {
            mainTitle: 'Galaxy Pulse',
            slug: 'galaxy-pulse',
            title: "Galaxy Pulse",
            duration: {12 : 43},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 7500, 3: 0.2},
            max: null,
            reContribute: {1: 7500, 3: 0.2},
            currencies: [1,3],
             productid: '',
            description: `<h5>Product Objective:</h5>
            <p>All-Inclusive Features from StellaNova Package - Equipped with a comprehensive suite of features including new business registration, dedicated business bank account, FSCA-approved compliance setup, website creation, and strategic financial management services.</p>
            <p>Enhanced Investment - A substantial investment amount of $7,500, facilitating more significant financial growth.</p>
            <p>4% Monthly Return - A steady and reliable interest income on your investment, sent directly to your wallet every month.</p>
            <p>New Business Registration: Kickstart your venture with our streamlined business registration process.</p>
            <p>Dedicated Business Bank Account: Manage your finances efficiently with a bank account dedicated to your new business.</p>
            <p>Promotors Business Compliance Setup: Ensure your business's legality and compliance, approved by FSCA.</p>
            <p>Website Creation: Establish your online presence with a professionally designed website.</p>
            <p>Accounting & Tax Services: Stay ahead with up to 75 bank transactions per month, monthly tax returns, and online filing software.</p><br />

            <h5>Benefits:</h5>
            <p>Optimized Growth Potential - Leverage a larger investment pool and superior features to maximize your financial growth and business success.</p>
            <p>Strategic Financial Management - Expertise in handling up to 75 transactions per month, enabling more refined and strategic financial management and planning.</p>
            <p>Peace of Mind - Hassle-free compliance, accounting, and tax services allowing you to focus on your business's core activities.</p>
            <br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months. Funds are locked for the 12-month period and proceeds are paid back into your wallet.</p><br />
        
            <p>Minimum Contribution: </p>
            <p>$7 500</p><br />`
        },
        {
            mainTitle: 'Supernova Spark',
            slug: 'supernova-spark',
            title: "Supernova Spark",
            duration: {12 : 44},
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            min: {1: 10000, 3: 0.27},
            max: null,
            reContribute: {1: 10000, 3: 0.27},
            currencies: [1,3],
             productid: '',
            description: `<h5>Product Objective:</h5>
            <p>Premium Features from GalaxyPulse Package - Access to all features of the preceding packages, providing a comprehensive and seamless experience.</p>
            <p>VIP Customer Representative - Exclusive access to a VIP representative ensuring personalized and priority support.</p>
            <p>Personal Basic Self Trading with MrOneDollar - Unique opportunity to engage in personal basic self-trading sessions with MrOneDollar, enhancing your trading knowledge and skills.</p>
            <p>4% Monthly Return on $10,000 or Above - Consistent and enhanced returns, fostering greater financial growth.</p>

            <h5>Benefits:</h5>
            <p>Exclusive Access & Support - Personalized attention and support from a VIP customer representative, ensuring all your needs and concerns are addressed promptly.</p>
            <p>Enhanced Learning Opportunity - Direct interaction and learning from MrOneDollar, offering insights and knowledge into effective trading strategies.</p>
            <p>Superior Financial Growth - With an investment of $10,000 or above, experience unprecedented financial growth and success.</p>
            <br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months. Funds are locked for the 12-month period and proceeds are paid back into your wallet.</p><br />
        
            <p>Minimum Contribution: </p>
            <p>$10 000</p><br />`
        },
    ]
    
    React.useEffect(res =>{
        // var redirectToDash = true;
        for(var i in products)
        {
  
            if(products[i].slug == product)
            {
        
              
                setRedirectToDash(false)
                setProductData(products[i])
                setProductDataTitle(products[i].mainTitle)
                return;
            }
        }

        // if(redirectToDash)
        //     window.location.href="/dashboard"

    },[auth])

    return (
        <div className="container mx-auto px-2 pt-8 pb-8">
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">{productDataTitle}</h1>
            {!redirectToDash ?
            <FundForm products={productData} min={productData.min} max={productData.max} />
            : ''}

          
        </div>
      )
}

export default ProductsPage